import React, { useEffect, useState } from "react";
import { CommentMode, Language, Style } from "../types";
import { auth, database } from "../firebase";
import { ref, onValue, off } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import StylePreview from "./StylePreview"; // 새로 만들 컴포넌트

interface SlideMenuProps {
  isOpen: boolean;
  onClose: () => void;
  mode: CommentMode;
  setMode: (mode: CommentMode) => void;
  language: Language;
  className?: string; // 추가된 prop
  onStyleSelect: (style: Style) => void; // 추가된 prop
}

const SlideMenu: React.FC<SlideMenuProps> = ({
  isOpen,
  onClose,
  mode,
  setMode,
  language,
  className, // 추가된 prop
  onStyleSelect, // 추가된 prop
}) => {
  const [user] = useAuthState(auth);
  const [myStyles, setMyStyles] = useState<Style[]>([]);
  const [addedStyles, setAddedStyles] = useState<Style[]>([]);
  const [hoveredStyle, setHoveredStyle] = useState<Style | null>(null);

  useEffect(() => {
    if (user) {
      const stylesRef = ref(database, "styles");
      const userAddedStylesRef = ref(database, `users/${user.uid}/addedStyles`);
      const userHiddenStylesRef = ref(
        database,
        `users/${user.uid}/hiddenStyles`
      );

      const unsubscribe = onValue(stylesRef, (snapshot) => {
        const allStyles = snapshot.val();
        if (allStyles) {
          onValue(userAddedStylesRef, (userAddedSnapshot) => {
            const userAddedStyles = userAddedSnapshot.val() || {};
            onValue(userHiddenStylesRef, (userHiddenSnapshot) => {
              const userHiddenStyles = userHiddenSnapshot.val() || {};

              const myStylesArray: Style[] = [];
              const addedStylesArray: Style[] = [];

              Object.entries(allStyles).forEach(
                ([styleId, styleData]: [string, any]) => {
                  if (!userHiddenStyles[styleId]) {
                    const style = {
                      id: styleId,
                      ...styleData,
                    };
                    if (styleData.createdBy === user.uid) {
                      myStylesArray.push(style);
                    } else if (userAddedStyles[styleId]) {
                      addedStylesArray.push(style);
                    }
                  }
                }
              );

              setMyStyles(
                myStylesArray.sort((a, b) => b.createdAt - a.createdAt)
              );
              setAddedStyles(
                addedStylesArray.sort((a, b) => b.createdAt - a.createdAt)
              );
            });
          });
        } else {
          setMyStyles([]);
          setAddedStyles([]);
        }
      });

      return () => {
        off(stylesRef);
        off(userAddedStylesRef);
        off(userHiddenStylesRef);
      };
    }
  }, [user]);

  const handleStyleClick = (style: Style) => {
    setMode(style.name as CommentMode);
    onStyleSelect(style); // 스타일 선택 시 onStyleSelect 호출
    onClose(); // 스타일 선택 후 메뉴 닫기
  };

  const handleStyleHover = (style: Style) => {
    setHoveredStyle(style);
  };

  const handleStyleLeave = () => {
    setHoveredStyle(null);
  };

  return (
    <div className="flex">
      <div
        className={`fixed top-0 left-0 w-72 h-full bg-gray-800 shadow-lg transform transition-transform duration-300 ease-in-out z-30 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div
          className={`flex flex-col h-full p-6 overflow-y-auto ${className}`}
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-white">
              {language === "ko" ? "스타일 선택" : "Select Style"}
            </h2>
            <button onClick={onClose} className="text-white text-2xl">
              ✕
            </button>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-semibold text-white mb-3">
              {language === "ko" ? "내 스타일" : "My Styles"}
            </h3>
            {myStyles.map((style) => (
              <button
                key={style.id}
                onClick={() => handleStyleClick(style)}
                onMouseEnter={() => handleStyleHover(style)}
                onMouseLeave={handleStyleLeave}
                className={`w-full text-left p-3 rounded mb-2 transition-colors duration-200 ${
                  mode === style.name
                    ? "bg-primary text-white"
                    : "text-gray-300 hover:bg-gray-700"
                }`}
              >
                {style.name}
              </button>
            ))}
          </div>

          <div>
            <h3 className="text-lg font-semibold text-white mb-3">
              {language === "ko" ? "추가된 스타일" : "Added Styles"}
            </h3>
            {addedStyles.map((style) => (
              <button
                key={style.id}
                onClick={() => handleStyleClick(style)}
                onMouseEnter={() => handleStyleHover(style)}
                onMouseLeave={handleStyleLeave}
                className={`w-full text-left p-3 rounded mb-2 transition-colors duration-200 ${
                  mode === style.name
                    ? "bg-primary text-white"
                    : "text-gray-300 hover:bg-gray-700"
                }`}
              >
                {style.name}
              </button>
            ))}
          </div>
        </div>
      </div>
      {hoveredStyle && (
        <StylePreview style={hoveredStyle} language={language} />
      )}
    </div>
  );
};

export default SlideMenu;

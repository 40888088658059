import React from "react";
import { Language } from "../types";

interface LanguageSelectorProps {
  language: Language;
  setLanguage: (language: Language) => void;
  className?: string;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ 
  language, 
  setLanguage,
  className
}) => {
  return (
    <button
      onClick={() => setLanguage(language === "ko" ? "en" : "ko")}
      className={className}
    >
      {language === "ko" ? "🇰🇷 KO" : "🇺🇸 EN"}
    </button>
  );
};

export default LanguageSelector;

import React, { useState } from "react";
import { Language } from "../types";

interface CodeLOLsLabInfoProps {
  language: Language;
  onClose: () => void;
  handleEmailClick: (type: "idea" | "inquiry") => void;
  handleSubscribe: () => Promise<boolean>; // Promise<boolean>을 반환하도록 수정
  userEmail: string | null;
}

const CodeLOLsLabInfo: React.FC<CodeLOLsLabInfoProps> = ({
  language,
  onClose,
  handleEmailClick,
  handleSubscribe,
  userEmail,
}) => {
  const [isSubscribed, setIsSubscribed] = useState(false);

  const onSubscribe = async () => {
    const success = await handleSubscribe();
    if (success) {
      setIsSubscribed(true);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-gray-800 p-8 rounded-lg max-w-2xl w-full text-white"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-2xl font-bold mb-4">
          {language === "ko"
            ? "CodeLOLs Lab: 웃음을 코딩하다"
            : "CodeLOLs Lab: Coding Laughter"}
        </h2>
        <p className="mb-4">
          {language === "ko"
            ? "CodeLOLs Lab은 '진지한 코딩'과 '말도 안 되는 아이디어'가 충돌하는 우리의 비밀 실험실입니다. 여기서는 버그가 피처가 되고, 무의미한 코드가 예술이 됩니다!"
            : "CodeLOLs Lab is our secret laboratory where 'serious coding' collides with 'ridiculous ideas'. Here, bugs become features, and meaningless code becomes art!"}
        </p>
        <p className="mb-4 italic">
          {language === "ko"
            ? "우리의 모토: 세상에 쓸모없을지라도, 재미있다면 코딩할 가치가 있다!"
            : "Our motto: If it's fun, it's worth coding, even if it's useless to the world!"}
        </p>
        <p className="mb-4">
          {language === "ko"
            ? "현재 개발 중인 '진지하게 말도 안 되는' 기능:"
            : "Our current 'seriously ridiculous' feature in development:"}
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            {language === "ko"
              ? "코드 구조 및 관계 시각화 도구 (일명: '스파게티 코드 디텍터')"
              : "Code Structure and Relationship Visualization Tool (aka 'Spaghetti Code Detector')"}
          </li>
        </ul>
        <div className="mt-8 mb-4">
          <h3 className="text-xl font-bold mb-2">
            {language === "ko"
              ? "CodeLOLs의 재미있는 이야기를 받아보세요!"
              : "Get CodeLOLs' Fun Stories!"}
          </h3>
          <p className="mb-4">
            {language === "ko"
              ? "구독하시면 개발 과정의 재미있는 이야기와 업데이트 소식을 보내드립니다."
              : "Subscribe to receive fun stories about our development process and updates."}
          </p>
          {userEmail ? (
            <div className="flex flex-col items-start">
              <button
                onClick={onSubscribe}
                disabled={isSubscribed}
                className={`flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-full transition-colors duration-200 ${
                  isSubscribed ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {isSubscribed ? (
                  <>
                    <span className="mr-2">✓</span>
                    {language === "ko" ? "구독됨" : "Subscribed"}
                  </>
                ) : language === "ko" ? (
                  "구독하기"
                ) : (
                  "Subscribe"
                )}
              </button>
              <div className="mt-8 mb-4">
                <h3 className="text-xl font-bold mb-2">
                  {language === "ko"
                    ? "후원해주시면 재미있는 것들을 많이 만들 수 있어요!"
                    : "Support us and we'll create more fun stuff!"}
                </h3>
                <p className="mb-4">
                  {language === "ko"
                    ? "후원해주시면 더 많은 재미있는 프로젝트를 진행할 수 있습니다. 감사합니다!"
                    : "Your support helps us to work on more fun projects. Thank you!"}
                </p>
                <div className="flex flex-col items-start">
                  <span className="text-yellow-300">
                    {language === "ko" ? "여기를 클릭하세요!" : "Click here!"}
                  </span>
                  <span className="animate-bounce">⬇️</span>
                  <a
                    href="https://buymeacoffee.com/kr2idiots"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-4 px-4 py-2 bg-yellow-400 text-black rounded-full flex items-center transition-transform transform hover:scale-110 hover:rotate-3"
                  >
                    <span className="mr-2">☕</span> Buy me a coffee
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-yellow-300">
              {language === "ko"
                ? "구독하려면 로그인이 필요합니다."
                : "Please log in to subscribe."}
            </p>
          )}
        </div>

        <div className="flex justify-between mt-8">
          <button
            onClick={() => handleEmailClick("idea")}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
          >
            {language === "ko"
              ? "말도 안 되는 아이디어 제안"
              : "Suggest a Ridiculous Idea"}
          </button>
          <button
            onClick={() => handleEmailClick("inquiry")}
            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
          >
            {language === "ko"
              ? "진지한 척 문의하기"
              : "Make a 'Serious' Inquiry"}
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
          >
            {language === "ko" ? "현실로 돌아가기" : "Return to Reality"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CodeLOLsLabInfo;

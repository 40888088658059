import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import {
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  linkWithPopup,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import DragCapture from "../components/DragCapture";
import MarqueeText from "../components/MarqueeText";
import { Link } from "react-router-dom"; // Link 컴포넌트 추가
interface LoginProps {
  language: "ko" | "en";
  setLanguage: (lang: "ko" | "en") => void;
}

interface LoginProps {
  language: "ko" | "en";
  setLanguage: (lang: "ko" | "en") => void;
}

const Login: React.FC<LoginProps> = ({ language, setLanguage }) => {
  const [currentExampleIndex, setCurrentExampleIndex] = useState(0);
  const [displayedCode, setDisplayedCode] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [selectedText, setSelectedText] = useState<{
    original: string;
    commented: string;
    position: { top: number; left: number };
  } | null>(null);

  const codeExamples = [
    {
      code: [
        `// 밈: 원영적 개발자
function calculateLove(you, me) {
  <span class="text-green-400">// 갑자기 사랑을 계산하는 함수가 생겼어 🥺 그런데 이 함수 덕분에 우리의 사랑을 수치화할 수 있으니까 완전 럭키비키잖아! 💛✨</span>
  return you + me + "= ❤️";
}`,
        `// 밈: 전라도 개발자
function cookKimchi(cabbage, redPepper) {
  <span class="text-green-400">// 아따, 이 함수는 김치를 담그는 겨? 배추랑 고춧가루만 있으먼 김치가 뚝딱 만들어지는 겨? 혀~! 🥬🌶️</span>
  return "맛있는 김치";
}`,
        `// 밈: 잼민 개발자
class SkoolLife {
  constructor(homework, friends) {
    this.homework = homework;
    this.friends = friends;
  }
  <span class="text-green-400">// 어쩔티비~ 저쩔티비~ 숙제랑 친구 있는 클래스 만들었쥬? 안물티비~ 안궁티비~ 😆📚👫 쿠쿠루삥뽕~ 지금 코드 이해 안 되죠? 개킹받죠? ㅋㅋㅋ 근데 어쩔 코드죠? ㅋㅋㅋㅋ</span>
  goToSchool() {
    console.log("학교 가기 귀찮다 ㅠㅠ 어쩔 등교~ 저쩔 하교~");
  }
}`,
      ],
      enCode: [
        `// Meme: Doge speak
function muchLogin(wow, such) {
  <span class="text-green-400">// Very secure, much password. Wow! Such authentication, very user. Amaze! 🐕✨</span>
  return wow.login(such.credentials);
}`,

        `// Meme: Rage Comics
class LoginAttempt {
  constructor(username, password) {
    this.username = username;
    this.password = password;
  }
  <span class="text-green-400">// Tries to log in for the 10th time... *FUUUUUUUUU* 😡</span>
  authenticate() {
    if (Math.random() > 0.9) {
      console.log("Login successful!");
    } else {
      console.log("WRONG PASSWORD AGAIN?! *Rage intensifies*");
    }
  }
}`,
        `// Meme: Pepe the Frog (Feels Bad Man)
function forgotPassword() {
  <span class="text-green-400">// When you can't remember if it's "password123" or "123password"... Feels bad, man. 🐸</span>
  return "Reset password link sent... to an email you don't remember.";
}`,
      ],
    },
  ];

  useEffect(() => {
    let isCancelled = false;
    const typeCode = async () => {
      const codeToType =
        language === "ko"
          ? codeExamples[currentExampleIndex].code[currentPage]
          : codeExamples[currentExampleIndex].enCode[currentPage];
      setDisplayedCode("");
      setIsTypingComplete(false);
      for (let i = 0; i < codeToType.length; i++) {
        if (isCancelled) return;
        setDisplayedCode((prev) => prev + codeToType[i]);
        await new Promise((resolve) => setTimeout(resolve, 50));
      }
      setIsTypingComplete(true);
    };

    typeCode();

    return () => {
      isCancelled = true;
    };
  }, [currentExampleIndex, currentPage, language]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isTypingComplete) {
      timer = setTimeout(() => {
        nextPage();
      }, 1000); // 타이핑이 완료된 후 1초 뒤에 다음 페이지로 넘어감
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isTypingComplete]);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error: any) {
      console.error("Google sign-in error:", error);
      alert(
        language === "ko"
          ? "로그인 중 오류가 발생했습니다."
          : "An error occurred during sign-in."
      );
    }
  };

  const signInWithGithub = async () => {
    const provider = new GithubAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error: any) {
      if (error.code === "auth/account-exists-with-different-credential") {
        const email = error.customData.email;
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);

        if (signInMethods.includes("google.com")) {
          const googleProvider = new GoogleAuthProvider();
          googleProvider.setCustomParameters({ login_hint: email });
          try {
            const result = await signInWithPopup(auth, googleProvider);
            await linkWithPopup(result.user, provider);
          } catch (linkError) {
            console.error("Error linking accounts:", linkError);
            alert(
              language === "ko"
                ? "계정 연동 중 오류가 발생했습니다."
                : "An error occurred while linking accounts."
            );
          }
        } else {
          alert(
            language === "ko"
              ? "이 이메일 주소는 이미 다른 로그인 방식으로 등록되어 있습니다."
              : "This email is already associated with another sign-in method."
          );
        }
      } else {
        console.error("GitHub sign-in error:", error);
        alert(
          language === "ko"
            ? "로그인 중 오류가 발생했습니다."
            : "An error occurred during sign-in."
        );
      }
    }
  };

  const nextPage = () => {
    if (currentPage < codeExamples[currentExampleIndex].code.length - 1) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(0);
      setCurrentExampleIndex(
        (prevIndex) => (prevIndex + 1) % codeExamples.length
      );
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    } else {
      const prevExampleIndex =
        (currentExampleIndex - 1 + codeExamples.length) % codeExamples.length;
      setCurrentExampleIndex(prevExampleIndex);
      setCurrentPage(codeExamples[prevExampleIndex].code.length - 1);
    }
  };

  const handleMouseUp = (event: MouseEvent) => {
    const selection = window.getSelection();
    const dragCaptureBox = document.getElementById("drag-capture-box");
    if (
      selection &&
      selection.toString().length > 0 &&
      dragCaptureBox?.contains(event.target as Node)
    ) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const original = selection.toString();
      const commented = original.replace(/^(.*)$/gm, "// $1");

      // 화면 경계를 고려한 위치 계산
      const buttonWidth = 120; // 버튼의 대략적인 너비
      const buttonHeight = 40; // 버튼의 대략적인 높이
      const maxX = window.innerWidth - buttonWidth;
      const maxY = window.innerHeight - buttonHeight;

      const posX = Math.min(event.clientX, maxX);
      const posY = Math.min(event.clientY, maxY);

      setSelectedText({
        original,
        commented,
        position: {
          top: posY,
          left: posX,
        },
      });
    } else {
      setSelectedText(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <div className="min-h-screen bg-background flex flex-col px-4 overflow-hidden">
      <div className="flex justify-end p-4">
        <div className="flex">
          <button
            onClick={() => setLanguage("ko")}
            className={`px-4 py-2 rounded-l-md ${
              language === "ko"
                ? "bg-primary text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            한국어
          </button>
          <button
            onClick={() => setLanguage("en")}
            className={`px-4 py-2 rounded-r-md ${
              language === "en"
                ? "bg-primary text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            English
          </button>
        </div>
      </div>
      {/* 중앙 상단에 텀스 및 개인정보 처리방침 링크 추가 */}
      <div className="absolute top-0 left-1/2 transform -translate-x-1/2 mt-4">
        <div className="flex justify-center space-x-4">
          <Link to="/terms" className="text-white">
            {language === "ko" ? "이용 약관" : "Terms of Service"}
          </Link>
          <Link to="/policy" className="text-white">
            {language === "ko" ? "개인정보 처리방침" : "Privacy Policy"}
          </Link>
        </div>
      </div>
      <div className="flex-grow flex items-center justify-center">
        <div className="max-w-2xl w-full space-y-8">
          <div>
            <h1 className="text-4xl font-bold text-center text-primary mb-2">
              CodeLOLs <span className="text-5xl">😄</span>
            </h1>
            <p className="text-center text-text text-lg mb-8">
              <MarqueeText
                text={
                  language === "ko"
                    ? "당신의 상사에게 혼날지도 모르지만 그건 제 알바가 아닙니다. 재밌으면 된거 잖아요? 한잔해~ 🍻 "
                    : "Your boss might lose it, but hey, we're just here for the laughs! If it's fun, it's a win! Let's party! 🎉 "
                }
                speed={50}
              />
            </p>
          </div>
          <div className="bg-surface rounded-lg shadow-lg p-4">
            <h3 className="text-xl font-semibold mb-4 text-center text-text">
              {language === "ko" ? "Example" : "Example"}
            </h3>
            <div className="bg-background text-text p-4 rounded-lg h-60 overflow-auto">
              <pre className="whitespace-pre-wrap">
                <code
                  dangerouslySetInnerHTML={{ __html: displayedCode }}
                ></code>
              </pre>
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={prevPage}
                className="bg-primary text-white px-4 py-2 rounded"
              >
                {language === "ko" ? "이전" : "Previous"}
              </button>
              <button
                onClick={nextPage}
                className="bg-primary text-white px-4 py-2 rounded"
              >
                {language === "ko" ? "다음" : "Next"}
              </button>
            </div>
          </div>

          {/* 드래그 캡처 기능 설명 추가 */}
          <div
            className="bg-surface rounded-lg shadow-lg p-8"
            id="drag-capture-box"
          >
            <h2 className="text-2xl font-semibold mb-4 text-text text-center">
              {language === "ko" ? "드래그 캡처 기능" : "Drag Capture Feature"}
            </h2>
            <div className="bg-background text-text p-4 rounded-lg h-60 overflow-auto">
              <pre className="whitespace-pre-wrap">
                <code>
                  {language === "ko"
                    ? `// 드래그하여 코드를 캡처하세요
function exampleFunction() {
  console.log("이 코드를 드래그하여 캡처하세요!");
}`
                    : `// Drag to capture the code
function exampleFunction() {
  console.log("Drag this code to capture it!");
}`}
                </code>
              </pre>
            </div>
          </div>

          <div className="bg-surface rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-semibold mb-4 text-text text-center">
              {language === "ko" ? "시작하기" : "Get Started"}
            </h2>
            <button
              onClick={signInWithGoogle}
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary mb-4"
            >
              <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"
                />
              </svg>
              {language === "ko" ? "Google로 로그인" : "Sign in with Google"}
            </button>
            <button
              onClick={signInWithGithub}
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
            >
              <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"
                />
              </svg>
              {language === "ko" ? "GitHub로 로그인" : "Sign in with GitHub"}
            </button>
          </div>
        </div>
      </div>
      <DragCapture selectedText={selectedText} language={language} />
    </div>
  );
};

export default Login;

import React, { useEffect, useState, useRef } from "react";

interface MarqueeTextProps {
  text: string;
  speed?: number;
}

const MarqueeText: React.FC<MarqueeTextProps> = ({ text, speed = 30 }) => {
  const [position, setPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const containerWidth = containerRef.current.offsetWidth;
    setPosition(containerWidth);

    const animation = setInterval(() => {
      setPosition((prevPosition) => {
        if (prevPosition <= -text.length * 20) {
          return containerWidth;
        }
        return prevPosition - 2; // 여기를 -1에서 -2로 변경하여 속도를 높였습니다.
      });
    }, speed);

    return () => clearInterval(animation);
  }, [text, speed]);

  return (
    <div ref={containerRef} className="overflow-hidden whitespace-nowrap">
      <span
        className="inline-block"
        style={{ transform: `translateX(${position}px)` }}
      >
        {text}
      </span>
    </div>
  );
};

export default MarqueeText;

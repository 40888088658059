import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { Link } from "react-router-dom";
import { Language } from "../types";

interface SubscriptionProps {
  language: Language;
}

const Subscription: React.FC<SubscriptionProps> = ({ language }) => {
  const [user] = useAuthState(auth);

  if (!user) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <div className="bg-gray-800 p-8 rounded-lg shadow-md">
          <p className="text-xl font-semibold text-white">
            {language === "ko"
              ? "구독 옵션을 보려면 로그인하세요."
              : "Please log in to view subscription options."}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-violet-600 text-white p-8">
      <Link
        to="/"
        className="text-white hover:text-gray-300 mb-8 inline-block text-lg"
      >
        ← {language === "ko" ? "홈으로 돌아가기" : "Back to Home"}
      </Link>
      <h1 className="text-4xl font-bold mb-12 text-center">
        {language === "ko" ? "CodeLOLs 구독 플랜" : "CodeLOLs Subscription Plans"}
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
        <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg backdrop-filter backdrop-blur-lg">
          <h2 className="text-2xl font-semibold mb-4">
            {language === "ko" ? "기본" : "Basic"}
          </h2>
          <p className="mb-4">
            {language === "ko" ? "코딩의 재미를 느껴보세요" : "Experience the fun of coding"}
          </p>
          <ul className="mb-4 list-disc list-inside">
            <li>{language === "ko" ? "일일 10개 코드 변환" : "10 code conversions per day"}</li>
            <li>{language === "ko" ? "기본 밈 스타일" : "Basic meme styles"}</li>
            <li>{language === "ko" ? "커뮤니티 접근" : "Community access"}</li>
          </ul>
          <p className="text-3xl font-bold mb-6">
            {language === "ko" ? "무료" : "Free"}
          </p>
          <button className="w-full bg-purple-600 text-white px-4 py-2 rounded-full font-semibold hover:bg-purple-700 transition duration-300">
            {language === "ko" ? "현재 플랜" : "Current Plan"}
          </button>
        </div>
        <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg backdrop-filter backdrop-blur-lg transform scale-105 shadow-xl border-2 border-purple-500">
          <h2 className="text-2xl font-semibold mb-4">
            {language === "ko" ? "프로" : "Pro"}
          </h2>
          <p className="mb-4">
            {language === "ko" ? "더 많은 기능으로 코딩을 즐기세요" : "Enjoy coding with more features"}
          </p>
          <ul className="mb-4 list-disc list-inside">
            <li>{language === "ko" ? "무제한 코드 변환" : "Unlimited code conversions"}</li>
            <li>{language === "ko" ? "프리미엄 밈 스타일" : "Premium meme styles"}</li>
            <li>{language === "ko" ? "AI 코드 리뷰" : "AI code review"}</li>
            <li>{language === "ko" ? "우선 고객 지원" : "Priority customer support"}</li>
          </ul>
          <p className="text-3xl font-bold mb-6">
            {language === "ko" ? "₩9,900/월" : "$9.99/month"}
          </p>
          <button className="w-full bg-purple-600 text-white px-4 py-2 rounded-full font-semibold hover:bg-purple-700 transition duration-300">
            {language === "ko" ? "업그레이드" : "Upgrade"}
          </button>
        </div>
        <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg backdrop-filter backdrop-blur-lg">
          <h2 className="text-2xl font-semibold mb-4">
            {language === "ko" ? "팀" : "Team"}
          </h2>
          <p className="mb-4">
            {language === "ko" ? "팀과 함께 코딩의 재미를 나누세요" : "Share the fun of coding with your team"}
          </p>
          <ul className="mb-4 list-disc list-inside">
            <li>{language === "ko" ? "모든 프로 기능 포함" : "All Pro features included"}</li>
            <li>{language === "ko" ? "팀원 협업 도구" : "Team collaboration tools"}</li>
            <li>{language === "ko" ? "사용자 지정 밈 템플릿" : "Custom meme templates"}</li>
            <li>{language === "ko" ? "전담 계정 관리자" : "Dedicated account manager"}</li>
          </ul>
          <p className="text-3xl font-bold mb-6">
            {language === "ko" ? "가격 문의" : "Contact us"}
          </p>
          <button className="w-full bg-purple-600 text-white px-4 py-2 rounded-full font-semibold hover:bg-purple-700 transition duration-300">
            {language === "ko" ? "문의하기" : "Contact Sales"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Subscription;

import { Style } from "../types";

export const calculateHotScore = (
  style: Style,
  newReactionCount: number,
  reactionType: string
): number => {
  const now = Date.now();
  const createdAt = style.createdAt || now;
  const ageInHours = (now - createdAt) / (1000 * 60 * 60);

  const baseScore =
    (style.reactions.like || 0) * 2 +
    (style.reactions.funny || 0) * 1.5 +
    (style.reactions.interesting || 0) * 1.5 +
    (style.reactions.risky || 0) * 1 +
    (style.addCount || 0) * 3;

  const updatedScore =
    baseScore +
    (reactionType === "like" ? 2 : 1) *
      (newReactionCount - (style.reactions[reactionType] || 0));

  return Math.round((updatedScore / Math.pow(ageInHours + 2, 1.8)) * 100);
};

// 기존의 다른 유틸리티 함수들...

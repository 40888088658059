import React, { useState } from "react";

interface TermsProps {
  type: "terms" | "policy";
}

const Terms: React.FC<TermsProps> = ({ type }) => {
  const [language, setLanguage] = useState<"en" | "ko">("en");

  const title = {
    en: type === "terms" ? "Terms of Service" : "Privacy Policy",
    ko: type === "terms" ? "이용약관" : "개인정보 처리방침",
  };

  const content = {
    en: type === "terms" ? termsContentEn : policyContentEn,
    ko: type === "terms" ? termsContentKo : policyContentKo,
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">{title[language]}</h1>
          {/* 언어 변경 버튼 */}
          <button
            onClick={() => setLanguage(language === "en" ? "ko" : "en")}
            className="px-4 py-2 bg-primary text-white rounded"
          >
            {language === "en" ? "한국어로 보기" : "View in English"}
          </button>
        </div>
        <div className="text-lg space-y-6">{content[language]}</div>
      </div>
    </div>
  );
};

// 영어 약관 내용
const termsContentEn = (
  <>
    <p>
      Welcome to CodeLOLs ("Service"). These Terms of Service ("Terms") govern
      your use of our Service. By accessing or using the Service, you agree to
      be bound by these Terms. If you do not agree to these Terms, please
      refrain from using the Service.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">1. Definitions</h2>
    <p>
      <strong>Service:</strong> All features and services provided through the
      CodeLOLs app.
      <br />
      <strong>User:</strong> Any individual or entity accessing or using the
      Service.
      <br />
      <strong>Content:</strong> All information, data, text, images, code, and
      other materials created, uploaded, or shared by Users within the Service.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">2. Acceptance of Terms</h2>
    <p>
      The Service is offered subject to your acceptance of these Terms. By
      creating an account or using the Service, you agree to these Terms.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">3. Eligibility</h2>
    <p>
      You must be at least 13 years old to use the Service. By using the
      Service, you represent and warrant that you meet this age requirement.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">4. User Accounts</h2>
    <p>
      You are responsible for maintaining the confidentiality of your account
      information and are fully responsible for all activities that occur under
      your account.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">5. Use of Service</h2>
    <p>
      You may use the code and styles generated by the Service for personal and
      commercial purposes, subject to these Terms. You are responsible for
      ensuring that your use complies with all applicable laws and regulations.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">
      6. Intellectual Property
    </h2>
    <p>
      All intellectual property rights in the Service are owned by us or our
      licensors. You retain ownership of any content you create or upload but
      grant us a license to use it as necessary to provide the Service.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">7. Prohibited Conduct</h2>
    <p>You agree not to engage in the following prohibited activities:</p>
    <ul className="list-disc pl-6">
      <li>Violating any applicable laws or regulations.</li>
      <li>Infringing upon the rights of others.</li>
      <li>Disrupting or interfering with the Service.</li>
      <li>Impersonating another person or entity.</li>
      <li>Uploading malicious code or software.</li>
    </ul>
    <h2 className="text-xl font-semibold mt-6 mb-3">
      8. Data Storage and Security
    </h2>
    <p>
      We store user data in Firebase Realtime Database located in the U.S. We
      employ reasonable security measures to protect your data but cannot
      guarantee absolute security.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">
      9. Subscription and Payment
    </h2>
    <p>
      The Service may offer paid subscriptions processed through PayPal. Payment
      information is handled securely by PayPal, and we do not store your
      payment details. Subscription cancellation and refund policies are
      outlined separately.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">10. Advertising</h2>
    <p>
      The Service may display advertisements via Google AdSense. These ads are
      subject to Google's privacy policies and terms.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">11. Termination</h2>
    <p>
      We reserve the right to suspend or terminate your access to the Service at
      our discretion, without notice, for conduct that violates these Terms.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">
      12. Limitation of Liability
    </h2>
    <p>
      To the fullest extent permitted by law, we shall not be liable for any
      indirect, incidental, special, consequential, or punitive damages arising
      out of your use of the Service.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">
      13. Disclaimer of Warranties
    </h2>
    <p>
      The Service is provided "as is" and "as available" without warranties of
      any kind, either express or implied.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">14. Changes to Terms</h2>
    <p>
      We may modify these Terms at any time. Changes will be effective upon
      posting to the Service. Your continued use constitutes acceptance of the
      new Terms.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">15. Governing Law</h2>
    <p>
      These Terms are governed by and construed in accordance with the laws of
      the jurisdiction in which the company is established, without regard to
      its conflict of law principles.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">16. Contact Information</h2>
    <p>
      For any questions about these Terms, please contact us at{" "}
      <a href="mailto:kr2idiots@gmail.com">kr2idiots@gmail.com</a>.
    </p>
  </>
);

// 한글 약관 내용
const termsContentKo = (
  <>
    <p>
      CodeLOLs("서비스")에 오신 것을 환영합니다. 이 이용약관("약관")은 서비스
      이용에 대한 조건을 규정합니다. 서비스를 액세스하거나 이용함으로써, 귀하는
      본 약관에 동의하는 것으로 간주됩니다. 만약 약관에 동의하지 않으신다면,
      서비스를 이용하지 말아 주십시오.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">1. 정의</h2>
    <p>
      <strong>서비스:</strong> CodeLOLs 앱을 통해 제공되는 모든 기능과 서비스를
      의미합니다.
      <br />
      <strong>사용자:</strong> 서비스를 액세스하거나 이용하는 개인 또는 단체를
      의미합니다.
      <br />
      <strong>콘텐츠:</strong> 사용자가 서비스 내에서 생성, 업로드 또는 공유하는
      모든 정보, 데이터, 텍스트, 이미지, 코드 및 기타 자료를 의미합니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">2. 약관의 수락</h2>
    <p>
      서비스는 귀하가 이 약관에 동의하는 경우에만 제공됩니다. 귀하가 계정을
      생성하거나 서비스를 이용하는 경우, 이 약관에 동의한 것으로 간주됩니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">3. 이용 자격</h2>
    <p>
      서비스를 이용하려면 최소 만 13세 이상이어야 합니다. 서비스를 이용함으로써
      귀하는 이 연령 요건을 충족함을 보증합니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">4. 사용자 계정</h2>
    <p>
      귀하는 계정 정보의 기밀성을 유지할 책임이 있으며, 귀하의 계정에서 발생하는
      모든 활동에 대해 전적으로 책임을 집니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">5. 서비스 이용</h2>
    <p>
      귀하는 이 약관에 따라 서비스에서 생성된 코드 및 스타일을 개인 및 상업적
      목적으로 사용할 수 있습니다. 귀하는 귀하의 사용이 모든 관련 법률 및 규정을
      준수하는지 확인할 책임이 있습니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">6. 지적 재산권</h2>
    <p>
      서비스의 모든 지적 재산권은 당사 또는 당사의 라이선스 제공자가 소유합니다.
      귀하는 귀하가 생성하거나 업로드한 콘텐츠에 대한 소유권을 유지하지만,
      서비스 제공을 위해 필요한 범위 내에서 당사에 라이선스를 부여합니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">7. 금지된 행위</h2>
    <p>귀하는 다음과 같은 금지된 활동에 참여하지 않을 것에 동의합니다:</p>
    <ul className="list-disc pl-6">
      <li>적용 가능한 법률 또는 규정을 위반하는 행위</li>
      <li>타인의 권리를 침해하는 행위</li>
      <li>서비스를 방해하거나 교란하는 행위</li>
      <li>다른 사람 또는 단체를 사칭하는 행위</li>
      <li>악성 코드 또는 소프트웨어를 업로드하는 행위</li>
    </ul>
    <h2 className="text-xl font-semibold mt-6 mb-3">8. 데이터 저장 및 보안</h2>
    <p>
      당사는 미국에 위치한 Firebase Realtime Database에 사용자 데이터를
      저장합니다. 당사는 귀하의 데이터를 보호하기 위해 합리적인 보안 조치를
      취하지만, 절대적인 보안을 보장할 수 없습니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">9. 구독 및 결제</h2>
    <p>
      서비스는 PayPal을 통해 처리되는 유료 구독을 제공할 수 있습니다. 결제
      정보는 PayPal에 의해 안전하게 처리되며, 당사는 귀하의 결제 정보를 저장하지
      않습니다. 구독 취소 및 환불 정책은 별도로 명시됩니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">10. 광고</h2>
    <p>
      서비스는 Google AdSense를 통해 광고를 표시할 수 있습니다. 이러한 광고는
      Google의 개인정보 보호정책 및 약관을 따릅니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">11. 서비스 종료</h2>
    <p>
      당사는 귀하가 이 약관을 위반하는 경우, 사전 통보 없이 서비스에 대한 접근을
      제한하거나 종료할 권리를 보유합니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">12. 책임 제한</h2>
    <p>
      법률이 허용하는 최대 범위 내에서, 당사는 귀하의 서비스 사용으로 인해
      발생하는 간접적, 부수적, 특수적, 결과적 또는 징벌적 손해에 대해 책임을
      지지 않습니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">13. 보증의 부인</h2>
    <p>
      서비스는 명시적 또는 묵시적 보증 없이 "있는 그대로" 및 "제공 가능한 범위
      내에서" 제공됩니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">14. 약관 변경</h2>
    <p>
      당사는 언제든지 이 약관을 수정할 수 있습니다. 변경 사항은 서비스에
      게시되는 즉시 효력이 발생합니다. 귀하가 계속해서 서비스를 이용하는 경우,
      변경된 약관에 동의하는 것으로 간주됩니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">15. 준거법</h2>
    <p>이 약관은 회사가 설립된 국가의 법률에 따라 규율되고 해석됩니다.</p>
    <h2 className="text-xl font-semibold mt-6 mb-3">16. 연락처</h2>
    <p>
      이 약관에 대한 질문이 있는 경우,{" "}
      <a href="mailto:kr2idiots@gmail.com">kr2idiots@gmail.com</a>으로
      문의하십시오.
    </p>
  </>
);

// 영어 개인정보 처리방침 내용
const policyContentEn = (
  <>
    <p>
      At CodeLOLs ("we", "us", or "our"), we are committed to protecting your
      privacy. This Privacy Policy outlines how we collect, use, and safeguard
      your personal information.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">
      1. Information We Collect
    </h2>
    <p>We may collect the following types of information:</p>
    <ul className="list-disc pl-6">
      <li>
        <strong>Personal Information:</strong> Email address, nickname, and
        optional profile image.
      </li>
      <li>
        <strong>Usage Data:</strong> Service usage records, IP address, cookies,
        and session information.
      </li>
      <li>
        <strong>Generated Content:</strong> Code and style information created
        during your use of the Service.
      </li>
    </ul>
    <h2 className="text-xl font-semibold mt-6 mb-3">
      2. How We Use Your Information
    </h2>
    <p>Your information is used for the following purposes:</p>
    <ul className="list-disc pl-6">
      <li>To provide and maintain the Service.</li>
      <li>To manage your account and provide customer support.</li>
      <li>To analyze usage and improve the Service.</li>
      <li>To send promotional communications (with your consent).</li>
      <li>To comply with legal obligations.</li>
    </ul>
    <h2 className="text-xl font-semibold mt-6 mb-3">3. Data Retention</h2>
    <p>
      We retain your personal information only as long as necessary for the
      purposes set out in this Privacy Policy and to comply with legal
      obligations.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">4. Data Security</h2>
    <p>
      We implement reasonable security measures to protect your personal
      information. However, no method of transmission over the internet is 100%
      secure.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">
      5. Third-Party Service Providers
    </h2>
    <p>
      We may employ third-party companies to facilitate our Service, such as
      PayPal for payment processing and Google AdSense for advertising. These
      third parties have access to your personal information only to perform
      these tasks on our behalf.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">
      6. International Data Transfer
    </h2>
    <p>
      Your information may be transferred to—and maintained on—servers located
      outside of your state or country. By using the Service, you consent to
      this transfer.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">7. Children's Privacy</h2>
    <p>
      Our Service is not intended for children under the age of 13. We do not
      knowingly collect personal information from children under 13.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">8. Your Rights</h2>
    <p>
      You have the right to access, correct, or delete your personal information
      held by us. To exercise these rights, please contact us at{" "}
      <a href="mailto:kr2idiots@gmail.com">kr2idiots@gmail.com</a>.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">9. Cookies</h2>
    <p>
      We use cookies to enhance your experience. You can instruct your browser
      to refuse all cookies or to indicate when a cookie is being sent.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">
      10. Changes to This Policy
    </h2>
    <p>
      We may update our Privacy Policy from time to time. Changes will be posted
      on this page, and the "Last Updated" date will be revised accordingly.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">11. Contact Us</h2>
    <p>
      If you have any questions about this Privacy Policy, please contact us at{" "}
      <a href="mailto:kr2idiots@gmail.com">kr2idiots@gmail.com</a>.
    </p>
  </>
);

// 한글 개인정보 처리방침 내용
const policyContentKo = (
  <>
    <p>
      CodeLOLs("당사")는 귀하의 개인정보 보호를 위해 최선을 다하고 있습니다. 본
      개인정보 처리방침은 당사가 어떠한 개인정보를 수집하고, 어떻게 사용하며,
      어떻게 보호하는지를 설명합니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">1. 수집하는 정보</h2>
    <p>당사는 다음과 같은 유형의 정보를 수집할 수 있습니다:</p>
    <ul className="list-disc pl-6">
      <li>
        <strong>개인 정보:</strong> 이메일 주소, 닉네임, 선택적 프로필 이미지.
      </li>
      <li>
        <strong>사용 데이터:</strong> 서비스 이용 기록, IP 주소, 쿠키, 세션
        정보.
      </li>
      <li>
        <strong>생성된 콘텐츠:</strong> 서비스 이용 중 생성된 코드 및 스타일
        정보.
      </li>
    </ul>
    <h2 className="text-xl font-semibold mt-6 mb-3">2. 개인정보 이용 목적</h2>
    <p>수집된 개인정보는 다음과 같은 목적으로 사용됩니다:</p>
    <ul className="list-disc pl-6">
      <li>서비스 제공 및 유지관리</li>
      <li>계정 관리 및 고객 지원</li>
      <li>서비스 이용 분석 및 개선</li>
      <li>광고 및 마케팅 정보 제공(사용자의 동의 시)</li>
      <li>법적 의무 준수</li>
    </ul>
    <h2 className="text-xl font-semibold mt-6 mb-3">3. 개인정보 보유 기간</h2>
    <p>
      당사는 개인정보 보호법 및 관련 법령에 따라 개인정보를 보유하며, 그 목적이
      달성되면 지체 없이 파기합니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">4. 데이터 보안</h2>
    <p>
      당사는 귀하의 개인정보를 보호하기 위해 합리적인 보안 조치를 시행합니다.
      그러나 인터넷을 통한 전송 방법은 100% 안전하지 않으며, 당사는 절대적인
      보안을 보장할 수 없습니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">5. 제3자 서비스 제공자</h2>
    <p>
      당사는 서비스 제공을 위해 제3자 회사를 이용할 수 있습니다. 예: PayPal을
      통한 결제 처리, Google AdSense를 통한 광고 제공. 이들 제3자는 당사의
      요청을 수행하기 위해 귀하의 개인정보에 접근할 수 있습니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">6. 국제 데이터 전송</h2>
    <p>
      귀하의 정보는 귀하의 국가 외부에 있는 서버로 전송될 수 있으며, 그곳에서
      유지 관리될 수 있습니다. 서비스를 이용함으로써 귀하는 이러한 데이터 전송에
      동의하게 됩니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">7. 아동의 개인정보 보호</h2>
    <p>
      당사의 서비스는 만 13세 미만의 아동을 대상으로 하지 않으며, 당사는 고의로
      만 13세 미만 아동의 개인정보를 수집하지 않습니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">8. 사용자의 권리</h2>
    <p>
      귀하는 당사가 보유한 귀하의 개인정보에 대해 열람, 수정 또는 삭제를 요청할
      권리가 있습니다. 이러한 권리를 행사하려면{" "}
      <a href="mailto:kr2idiots@gmail.com">kr2idiots@gmail.com</a>으로
      문의하십시오.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">9. 쿠키</h2>
    <p>
      당사는 귀하의 경험을 향상시키기 위해 쿠키를 사용합니다. 귀하는 브라우저
      설정을 통해 모든 쿠키를 거부하거나 쿠키가 전송될 때 알림을 받도록 설정할
      수 있습니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">
      10. 개인정보 처리방침 변경
    </h2>
    <p>
      당사는 개인정보 처리방침을 수시로 업데이트할 수 있으며, 변경 사항은 이
      페이지에 게시됩니다. 마지막 업데이트 날짜는 "최종 수정일"을 기준으로
      갱신됩니다.
    </p>
    <h2 className="text-xl font-semibold mt-6 mb-3">11. 연락처</h2>
    <p>
      이 개인정보 처리방침에 대한 질문이 있는 경우,{" "}
      <a href="mailto:kr2idiots@gmail.com">kr2idiots@gmail.com</a>으로
      문의하십시오.
    </p>
  </>
);

export default Terms;

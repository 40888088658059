import React, { useState, KeyboardEvent } from "react";
import { Language } from "../types";

interface CodeInputProps {
  onSubmit: (code: string) => void;
  language: Language;
}

const CodeInput: React.FC<CodeInputProps> = ({ onSubmit, language }) => {
  const [code, setCode] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (code.trim()) {
      onSubmit(code.trim());
      setCode("");
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const placeholderText =
    language === "ko"
      ? "여기에 코드를 입력하세요..."
      : "Enter your code here...";
  const buttonText = language === "ko" ? "CodeLOLs 생성" : "Get CodeLOLs";

  return (
    <form onSubmit={handleSubmit} className="flex items-center">
      <textarea
        value={code}
        onChange={(e) => setCode(e.target.value)}
        onKeyDown={handleKeyDown}
        className="flex-grow p-2 bg-background text-text border border-primary rounded-l resize-none focus:ring-2 focus:ring-primary h-10 min-h-0"
        placeholder={placeholderText}
        rows={1}
      />
      <button
        type="submit"
        className="bg-primary text-white px-4 py-2 rounded-r hover:bg-secondary transition-colors h-10"
      >
        {buttonText}
      </button>
    </form>
  );
};

export default CodeInput;

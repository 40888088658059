import React, { useState } from "react";
import html2canvas from "html2canvas";

interface DragCaptureProps {
  selectedText: {
    original: string;
    commented: string;
    position: {
      top: number;
      left: number;
    };
  } | null;
  language: string;
  isLoginPage?: boolean;
  onCapture?: (capturedImage: string) => void;
}

const DragCapture: React.FC<DragCaptureProps> = ({
  selectedText,
  language,
  isLoginPage = false,
  onCapture,
}) => {
  const [isCapturing, setIsCapturing] = useState(false);

  const highlightComments = (code: string) => {
    return code.replace(
      /(\/\/.*)/g,
      '<span style="color: #10b981; font-weight: bold;">$1</span>'
    );
  };

  const escapeHtml = (unsafe: string) => {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  };

  const captureSelection = () => {
    if (selectedText) {
      setIsCapturing(true);
      const captureElement = document.createElement("div");
      captureElement.innerHTML = `
        <div style="background-color: #1f2937; color: #f3f4f6; padding: 20px; font-family: Arial, sans-serif; width: 800px;">
          <h1 style="color: #6366f1; text-align: center; font-size: 36px; margin-bottom: 20px;">CodeLOLs.com</h1>
          <div style="display: flex; justify-content: space-between;">
            <div style="width: 48%;">
              <h3 style="color: #8b5cf6; font-size: 24px; margin-bottom: 10px;">${
                language === "ko" ? "원본 코드" : "Original Code"
              }</h3>
              <pre style="background-color: #374151; padding: 15px; border-radius: 8px; white-space: pre-wrap; font-size: 14px; margin: 0; overflow-x: auto; max-width: 100%; color: #f3f4f6;">${escapeHtml(
                selectedText.original
              )}</pre>
            </div>
            <div style="width: 48%;">
              <h3 style="color: #8b5cf6; font-size: 24px; margin-bottom: 10px;">${
                language === "ko" ? "생성된 코드" : "Generated Code"
              }</h3>
              <pre style="background-color: #374151; padding: 15px; border-radius: 8px; white-space: pre-wrap; font-size: 14px; margin: 0; overflow-x: auto; max-width: 100%; color: #f3f4f6;">${highlightComments(
                escapeHtml(selectedText.commented)
              )}</pre>
            </div>
          </div>
        </div>
      `;
      document.body.appendChild(captureElement);
      html2canvas(captureElement, {
        scale: 2,
        width: 800,
        height: captureElement.offsetHeight,
        backgroundColor: null,
      }).then((canvas) => {
        const capturedImage = canvas.toDataURL();
        if (isLoginPage && onCapture) {
          onCapture(capturedImage);
        } else {
          const link = document.createElement("a");
          link.download = "codelols-selection-capture.png";
          link.href = capturedImage;
          link.click();
        }
        document.body.removeChild(captureElement);
        setIsCapturing(false);
      });
    }
  };

  if (!selectedText) return null;

  return (
    <div
      className={`fixed bg-surface p-2 rounded-lg shadow-lg transition-opacity duration-500 ${
        isCapturing ? "opacity-0" : "opacity-100"
      }`}
      style={{
        top: `${selectedText.position.top}px`,
        left: `${selectedText.position.left}px`,
        transform: "translate(20px, -50%)", // 버튼을 마우스 포인터 오른쪽으로 이동하고 수직 중앙에 배치
      }}
    >
      <button
        onClick={captureSelection}
        className="bg-primary text-white px-3 py-1 rounded hover:bg-secondary transition-colors text-sm"
      >
        {language === "ko" ? "드래그 캡처" : "Capture Selection"}
      </button>
    </div>
  );
};

export default DragCapture;

import React from "react";
import { Style } from "../types"; // Style 타입을 import 해야 합니다.

interface HeaderProps {
  setIsMenuOpen: (isOpen: boolean) => void;
  setIsRightMenuOpen: (isOpen: boolean) => void;
  selectedStyle: Style | null; // 새로 추가된 prop
}

export const Header: React.FC<HeaderProps> = ({
  setIsMenuOpen,
  setIsRightMenuOpen,
  selectedStyle,
}) => {
  return (
    <header className="bg-surface p-4 flex justify-between items-center">
      <div className="flex items-center">
        <button onClick={() => setIsMenuOpen(true)} className="text-2xl mr-2">
          👚
        </button>
        {selectedStyle && (
          <span className="text-primary">{selectedStyle.name}</span>
        )}
      </div>
      <h1 className="text-2xl font-bold text-primary">
        CodeLOLs <span className="text-3xl">😄</span>
      </h1>
      <div className="flex items-center">
        <a
          href="https://buymeacoffee.com/kr2idiots"
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2 px-4 py-2 bg-yellow-400 text-black rounded-full flex items-center transition-transform transform hover:scale-110 hover:rotate-3"
        >
          <span className="mr-2">☕</span> Buy me a coffee
        </a>
        <button
          onClick={() => setIsRightMenuOpen(true)}
          className="text-2xl ml-2"
        >
          ⚙️
        </button>
      </div>
    </header>
  );
};

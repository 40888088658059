import React from "react";
import { Language } from "../types";
import DOMPurify from 'dompurify';

interface CodeComparisonProps {
  originalCode: string;
  commentedCode: string;
  language: Language;
  scrollbarStyles: string;
}

const CodeComparison: React.FC<CodeComparisonProps> = ({
  originalCode,
  commentedCode,
  language,
  scrollbarStyles,
}) => {
  const sanitizeHTML = (html: string) => {
    return {
      __html: DOMPurify.sanitize(html, { USE_PROFILES: { html: true } })
    };
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="bg-gray-800 p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-2 text-white">
          {language === "ko" ? "원본 코드" : "Original Code"}
        </h3>
        <pre className="bg-gray-700 p-3 rounded-lg overflow-y-auto custom-scrollbar max-h-60">
          <code className="text-sm text-gray-300 whitespace-pre-wrap break-words">{originalCode}</code>
        </pre>
      </div>
      <div className="bg-gray-800 p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-2 text-white">
          {language === "ko" ? "생성된 코드" : "Generated Code"}
        </h3>
        <pre className="bg-gray-700 p-3 rounded-lg overflow-y-auto custom-scrollbar max-h-60">
          <code 
            className="text-sm text-gray-300 whitespace-pre-wrap break-words"
            dangerouslySetInnerHTML={sanitizeHTML(commentedCode)}
          />
        </pre>
      </div>
    </div>
  );
};

export default CodeComparison;

import React, { useState, useEffect } from "react";
import { auth, database } from "../firebase";
import {
  ref,
  push,
  set,
  onValue,
  off,
  update,
  get,
  serverTimestamp,
  query,
  orderByChild,
  limitToLast,
  equalTo,
} from "firebase/database";
import { Link } from "react-router-dom";
import StyleCard from "../components/StyleCard";
import { calculateHotScore } from "../utils/Store_Utils";
import {
  Style,
  UserReactions,
  Comment,
  Language,
  StyleCategory,
  CategoryEmojis,
  CategoryTranslations,
  ReactionEmojis,
  ReactionTranslations,
} from "../types";
import LanguageSelector from "../components/LanguageSelector";
import { motion, AnimatePresence } from "framer-motion";
import { useAuthState } from "react-firebase-hooks/auth";
import { functions } from "../firebase"; // Firebase 함수 import
import { httpsCallable } from "firebase/functions";
import { useLanguage } from "../contexts/LanguageContext";

// getReactionCount 함수 추가
const getReactionCount = (reaction: any): number => {
  if (typeof reaction === "number") {
    return reaction;
  } else if (typeof reaction === "object" && reaction !== null) {
    return Object.values(reaction).reduce(
      (sum: number, value: any) =>
        sum + (typeof value === "number" ? value : 0),
      0
    );
  }
  return 0;
};

const SuccessAlert: React.FC<{ message: string; onClose: () => void }> = ({
  message,
  onClose,
}) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg z-50"
      >
        <div className="flex items-center">
          <svg
            className="w-6 h-6 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
          <span>{message}</span>
        </div>
        <button
          onClick={onClose}
          className="absolute top-1 right-1 text-white hover:text-gray-200"
        >
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </motion.div>
    </AnimatePresence>
  );
};

const StyleStore: React.FC = () => {
  const { language, setLanguage } = useLanguage();
  const [user] = useAuthState(auth);
  const [styles, setStyles] = useState<Style[]>([]);
  const [newStyle, setNewStyle] = useState({
    name: "",
    description: "",
    code: "",
    category: "Meme" as StyleCategory,
    language: "en" as Language,
  });
  const [userReactions, setUserReactions] = useState<UserReactions>({});
  const [comments, setComments] = useState<{ [styleId: string]: Comment[] }>(
    {}
  );
  const [dailyStyleCount, setDailyStyleCount] = useState(0);
  const [remainingStyles, setRemainingStyles] = useState(15);
  const [hotStyles, setHotStyles] = useState<Style[]>([]);
  const [addedStyles, setAddedStyles] = useState<Set<string>>(new Set());
  const [isCreating, setIsCreating] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<
    StyleCategory | "All"
  >("All");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [loadingStyles, setLoadingStyles] = useState<Set<string>>(new Set());
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showLimitAlert, setShowLimitAlert] = useState(false);
  const [limitAlertMessage, setLimitAlertMessage] = useState("");

  useEffect(() => {
    const stylesRef = ref(database, "styles");
    const hotStylesQuery = query(
      stylesRef,
      orderByChild("hotScore"),
      limitToLast(20)
    );

    onValue(hotStylesQuery, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const hotStylesList = Object.entries(data)
          .map(([id, style]: [string, any]) => ({
            id,
            ...style,
          }))
          .filter((style: Style) => style.hotScore && style.hotScore >= 50) // hotScore가 50 이상인 스타일만 선택
          .sort((a: Style, b: Style) => (b.hotScore || 0) - (a.hotScore || 0))
          .slice(0, 10); // 상위 10개만 선택
        setHotStyles(hotStylesList);
      }
    });

    const stylesListener = onValue(stylesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const stylesArray = Object.entries(data).map(
          ([key, value]: [string, any]) => {
            const reactions = value.reactions || {};
            // 코드가 없는 경우 로딩 상태로 설정
            if (!value.code) {
              setLoadingStyles((prev) => new Set(prev).add(key));
            }

            return {
              id: key,
              ...value,
              reactions: {
                like: getReactionCount(reactions.like),
                funny: getReactionCount(reactions.funny),
                interesting: getReactionCount(reactions.interesting),
                risky: getReactionCount(reactions.risky),
              },
              comments: value.comments || [],
              addCount: value.addCount || 0,
            };
          }
        );
        setStyles(stylesArray);

        // comments 상태 업데이트
        const commentsObj: { [styleId: string]: Comment[] } = {};
        stylesArray.forEach((style) => {
          commentsObj[style.id] = style.comments;
        });
        setComments(commentsObj);
      }
      setIsLoading(false);
    });

    const userReactionsRef = ref(
      database,
      `userReactions/${auth.currentUser?.uid}`
    );
    onValue(userReactionsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setUserReactions(data);
      }
    });

    // 사용자의 일일 스타일 생성 수 가져오기
    if (user) {
      const dailyStyleCountRef = ref(
        database,
        `users/${user.uid}/dailyStyleCount`
      );
      const nicknameRef = ref(database, `users/${user.uid}/nickname`);

      onValue(dailyStyleCountRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const { count, date } = data;
          const today = new Date().toDateString();
          if (date === today) {
            setDailyStyleCount(count);
            setRemainingStyles(15 - count);
          } else {
            // 날짜가 다르면 카트 리셋
            setDailyStyleCount(0);
            setRemainingStyles(15);
            set(dailyStyleCountRef, { count: 0, date: today });
          }
        } else {
          setRemainingStyles(15);
        }
      });

      // 사용자 닉네임 가져오기
      get(nicknameRef).then((snapshot) => {
        if (snapshot.exists()) {
          // 닉네임이 존재하면 여기서 필요한 작업을 수행합니다.
          // 예: console.log("User nickname:", snapshot.val());
        }
      });
    }

    if (user) {
      const userStylesRef = ref(database, `users/${user.uid}/styles`);
      onValue(userStylesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setAddedStyles(new Set(Object.keys(data)));
        }
      });
    }

    return () => {
      off(stylesRef, "value", stylesListener);
      off(userReactionsRef);
      if (user) {
        const dailyStyleCountRef = ref(
          database,
          `users/${user.uid}/dailyStyleCount`
        );
        off(dailyStyleCountRef);
      }
    };
  }, [user]);

  useEffect(() => {
    // 사용의  언어로 newStyle의 language 필드 초기화
    setNewStyle((prev) => ({ ...prev, language: language }));
  }, [language]);

  // 스타일 코드가 생성되면 로딩 상태 제거
  useEffect(() => {
    styles.forEach((style) => {
      if (style.code && loadingStyles.has(style.id)) {
        setLoadingStyles((prev) => {
          const newSet = new Set(prev);
          newSet.delete(style.id);
          return newSet;
        });
      }
    });
  }, [styles, loadingStyles]);

  const createStyle = async () => {
    if (user) {
      // 입력 검증
      if (!newStyle.name || !newStyle.description) {
        setErrorMessage(
          language === "ko"
            ? "이름과 설명을 모두 입력해 주세요."
            : "Please enter both name and description."
        );
        return;
      }

      if (newStyle.name.length > 20) {
        setErrorMessage(
          language === "ko"
            ? "이름은 20자 이내로 입력해 주세요."
            : "Name must be 20 characters or less."
        );
        return;
      }

      if (newStyle.description.length > 150) {
        setErrorMessage(
          language === "ko"
            ? "설명은 150자 이내로 입력해 주세요."
            : "Description must be 150 characters or less."
        );
        return;
      }

      if (dailyStyleCount >= 15) {
        const message =
          language === "ko"
            ? "오늘의 스타일 생성 한도(15개)에 도달했습니다. 😅\n\n" +
              "개인 개발자로서 API 요청이 너무 많아지면 감당하기 어려워요.\n" +
              "내일 다시 와주세요!\n\n" +
              "프로젝트가 마음에 드셨다면, 구독 지원 부탁드립니다! 🙏"
            : "You've reached today's style creation limit (15)! 😅\n\n" +
              "As a solo developer, too many API requests are hard to handle.\n" +
              "Please come back tomorrow!\n\n" +
              "If you enjoyed my project, consider subscribing to support! 🙏";
        setLimitAlertMessage(message);
        setShowLimitAlert(true);
        return;
      }

      if (dailyStyleCount === 10) {
        const message =
          language === "ko"
            ? "주의: 오늘 10개의 스타일을 만드셨네요! 🚀\n\n" +
              "15개 한도에 가까워지고 있어요.\n" +
              "너무 많은 스타일이 생성되면 서버에 부담이 될 수 있어요. 😢\n\n" +
              "개인 개발자로 프로젝트를 진행 중입니다.\n" +
              "구독해 주시면 큰 힘이 될 거예요! 💖"
            : "Warning: You've created 10 styles today! 🚀\n\n" +
              "You're approaching the daily limit of 15 styles.\n" +
              "If too many styles are created, it might strain the server. 😢\n\n" +
              "I'm developing this project solo.\n" +
              "Your subscription would mean the world to me! 💖";
        setLimitAlertMessage(message);
        setShowLimitAlert(true);
        return;
      }

      setIsCreating(true);
      setErrorMessage(null);

      try {
        const newStyleRef = push(ref(database, "styles"));

        // nickname 가져오기
        const nicknameRef = ref(database, `users/${user.uid}/nickname`);
        const nicknameSnapshot = await get(nicknameRef);
        const nickname =
          nicknameSnapshot.val() || user.displayName || user.email;

        // today 정의
        const today = new Date().toDateString();

        // dailyStyleCountRef 정의
        const dailyStyleCountRef = ref(
          database,
          `users/${user.uid}/dailyStyleCount`
        );

        // 기본 스타일 데이터 저장
        await set(newStyleRef, {
          ...newStyle,
          createdBy: user.uid,
          creatorNickname: nickname,
          createdAt: serverTimestamp(),
          reactions: { like: 0, funny: 0, interesting: 0, risky: 0 },
          comments: [],
          addCount: 0,
        });

        // Firebase Function 호출 (한 번만 호출)
        const generateStyleExample = httpsCallable(
          functions,
          "generateStyleExampleCallable"
        );
        try {
          const result = await generateStyleExample({
            styleId: newStyleRef.key,
            description: newStyle.description,
            language: newStyle.language,
          });

          let generatedCode = result.data as string;
          if (typeof generatedCode !== "string") {
            console.error(
              "Invalid generated code type:",
              typeof generatedCode,
              generatedCode
            );
            generatedCode = JSON.stringify(generatedCode);
          }

          // 생성된 코드로 스타일 업데이트
          await update(newStyleRef, { code: generatedCode });
        } catch (error) {
          console.error("Error calling generateStyleExample:", error);
          throw error; // 상위 catch 블록에서 처리할 수 있도록 에러를 다시 던집니다.
        }

        // 일일 스타일 생성 수 업데이트
        const newCount = dailyStyleCount + 1;
        await set(dailyStyleCountRef, { count: newCount, date: today });
        setDailyStyleCount(newCount);
        setRemainingStyles(15 - newCount);

        setNewStyle({
          name: "",
          description: "",
          code: "",
          category: "Meme",
          language: "en",
        });

        setShowSuccessAlert(true);
        setTimeout(() => setShowSuccessAlert(false), 3000);

        handleCloseForm();
      } catch (error) {
        console.error("Error creating style:", error);
        setErrorMessage(
          language === "ko"
            ? "스타일 생성 중 오류가 발생했습니다."
            : "An error occurred while creating the style."
        );
      } finally {
        setIsCreating(false);
      }
    }
  };

  const handleReaction = (
    style: Style,
    reactionType: "like" | "funny" | "interesting" | "risky"
  ) => {
    if (!user) {
      alert(
        language === "ko"
          ? "반응을 남기려면 로그인이 필요합니다."
          : "You need to be logged in to react."
      );
      return;
    }

    const userReactionRef = ref(
      database,
      `userReactions/${user.uid}/${style.id}`
    );

    get(userReactionRef).then((snapshot) => {
      const currentReactions = snapshot.val() || {};
      const currentReactionType = Object.keys(currentReactions).find(
        (key) => currentReactions[key] === true
      );

      if (currentReactionType === reactionType) {
        // 같은  반응을 다시 클릭한 경우: 반응 제거
        update(userReactionRef, { [reactionType]: null }).then(() =>
          updateStyleReaction(style, reactionType, -1)
        );
      } else if (currentReactionType) {
        // 다른 반응이 이미 있는 경우: 기존 응 제거 후 새 반응 추가
        update(userReactionRef, {
          [currentReactionType]: null,
          [reactionType]: true,
        }).then(() => {
          updateStyleReaction(style, currentReactionType, -1);
          updateStyleReaction(style, reactionType, 1);
        });
      } else {
        // 새운 반응 추가
        update(userReactionRef, { [reactionType]: true }).then(() =>
          updateStyleReaction(style, reactionType, 1)
        );
      }
    });
  };

  const updateStyleReaction = (
    style: Style,
    reactionType: string,
    change: number
  ) => {
    const styleReactionRef = ref(
      database,
      `styles/${style.id}/reactions/${reactionType}`
    );

    get(styleReactionRef).then((snapshot) => {
      const currentCount = snapshot.val() || 0;
      const newCount = Math.max(currentCount + change, 0);

      const hotScore = calculateHotScore(style, newCount, reactionType);

      update(ref(database, `styles/${style.id}`), {
        [`reactions/${reactionType}`]: newCount,
        hotScore: hotScore,
      });
    });
  };

  const addToMyStyles = (style: Style) => {
    if (user) {
      const userAddedStyleRef = ref(
        database,
        `users/${user.uid}/addedStyles/${style.id}`
      );
      const styleAddCountRef = ref(database, `styles/${style.id}/addCount`);

      if (addedStyles.has(style.id)) {
        // 이미 추가된 스타일이면 제거
        set(userAddedStyleRef, null);
        get(styleAddCountRef).then((snapshot) => {
          const currentAddCount = snapshot.val() || 0;
          if (currentAddCount > 0) {
            update(styleAddCountRef, { addCount: currentAddCount - 1 });
          }
        });
        setAddedStyles((prev) => {
          const newSet = new Set(prev);
          newSet.delete(style.id);
          return newSet;
        });
      } else {
        // 새로운 스타일 추가
        set(userAddedStyleRef, true);
        get(styleAddCountRef).then((snapshot) => {
          const currentAddCount = snapshot.val() || 0;
          update(styleAddCountRef, { addCount: currentAddCount + 1 });
        });
        setAddedStyles((prev) => new Set(prev).add(style.id));
      }
    }
  };

  const addComment = async (styleId: string, commentText: string) => {
    if (user && commentText.trim()) {
      const nicknameRef = ref(database, `users/${user.uid}/nickname`);
      const nicknameSnapshot = await get(nicknameRef);
      const nickname = nicknameSnapshot.val() || user.displayName || user.email;

      const styleRef = ref(database, `styles/${styleId}`);
      const styleSnapshot = await get(styleRef);
      const styleData = styleSnapshot.val();

      if (styleData) {
        const newComment: Comment = {
          id: Date.now().toString(), // 고유한 ID 생성
          text: commentText,
          createdBy: nickname,
          createdAt: Date.now(),
        };

        const updatedComments = [...(styleData.comments || []), newComment];

        // 스타일 객체 내의 comments 배열 업데이트
        await update(styleRef, { comments: updatedComments });

        // 로컬 상태 업데이트
        setComments((prevComments) => ({
          ...prevComments,
          [styleId]: updatedComments,
        }));

        // styles 상태 업데이트
        setStyles((prevStyles) =>
          prevStyles.map((style) =>
            style.id === styleId
              ? { ...style, comments: updatedComments }
              : style
          )
        );
      }
    }
  };

  const filteredStyles = styles.filter((style) => {
    const matchesSearch =
      style.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      style.description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory =
      selectedCategory === "All" || style.category === selectedCategory;
    const matchesLanguage = style.language === language;
    return matchesSearch && matchesCategory && matchesLanguage;
  });

  const styleCategories = [
    { id: "newest", title: language === "ko" ? "🎉 최신" : "🎉 Newest" },
    { id: "hot", title: "HOT 🔥", emoji: "🔥" },
    {
      id: "like",
      title: ReactionTranslations.like[language],
      emoji: ReactionEmojis.like,
    },
    {
      id: "funny",
      title: ReactionTranslations.funny[language],
      emoji: ReactionEmojis.funny,
    },
    {
      id: "interesting",
      title: ReactionTranslations.interesting[language],
      emoji: ReactionEmojis.interesting,
    },
    {
      id: "risky",
      title: ReactionTranslations.risky[language],
      emoji: ReactionEmojis.risky,
    },
  ];

  // 선택된 카테고리 상태 수정
  const [selectedStyleCategory, setSelectedStyleCategory] = useState("newest");

  // 카테고리별 스타일 필터링 함수
  const getFilteredStyles = (categoryId: string) => {
    switch (categoryId) {
      case "hot":
        return hotStyles.filter(
          (style) =>
            (selectedCategory === "All" ||
              style.category === selectedCategory) &&
            style.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            style.language === language
        );
      case "newest":
        return filteredStyles.sort((a, b) => b.createdAt - a.createdAt);
      case "like":
      case "funny":
      case "interesting":
      case "risky":
        return filteredStyles
          .filter((s) => s.reactions[categoryId] > 0)
          .sort((a, b) => b.reactions[categoryId] - a.reactions[categoryId]);
      default:
        return filteredStyles;
    }
  };

  const handleCreateStyleClick = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  const handleCategoryClick = (categoryTitle: string) => {
    setExpandedCategory(
      expandedCategory === categoryTitle ? null : categoryTitle
    );
  };

  if (isLoading) {
    return <div className="text-center text-white mt-10">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white relative">
      <header className="bg-gray-800 p-4 flex justify-between items-center">
        <Link to="/" className="text-2xl">
          👈
        </Link>
        <h1 className="text-2xl font-bold text-primary">Style Store</h1>
        <div className="flex items-center space-x-4">
          <Link to="/subscription">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 py-2 rounded-full font-semibold shadow-lg hover:from-purple-600 hover:to-pink-600 transition duration-300"
            >
              {language === "ko" ? "🚀 파워업!" : "🚀 Power Up!"}
            </motion.button>
          </Link>
          <LanguageSelector
            language={language}
            setLanguage={setLanguage}
            className="bg-gray-700 text-white px-4 py-2 rounded-full font-semibold shadow-lg hover:bg-gray-600 transition duration-300"
          />
        </div>
      </header>

      <main className="container mx-auto p-4 pb-20">
        <div className="mb-4 flex space-x-4">
          <select
            value={selectedCategory}
            onChange={(e) =>
              setSelectedCategory(e.target.value as StyleCategory | "All")
            }
            className="p-2 bg-gray-800 rounded"
          >
            <option value="All">
              {language === "ko" ? "모든 카테고리" : "All Categories"}
            </option>
            {Object.entries(CategoryTranslations).map(([key, value]) => (
              <option key={key} value={key}>
                {CategoryEmojis[key as StyleCategory]} {value[language]}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder={
              language === "ko" ? "스타일 검색..." : "Search styles..."
            }
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow p-2 bg-gray-800 rounded"
          />
        </div>

        {/* 스타일 카테고리 탭 */}
        <div className="flex space-x-2 mb-4 overflow-x-auto">
          {styleCategories.map((category) => (
            <button
              key={category.id}
              onClick={() => setSelectedStyleCategory(category.id)}
              className={`px-4 py-2 rounded-full ${
                selectedStyleCategory === category.id
                  ? "bg-primary text-white"
                  : "bg-gray-700 text-gray-300 hover:bg-gray-600"
              }`}
            >
              {category.emoji && <span className="mr-2">{category.emoji}</span>}
              {category.title}
            </button>
          ))}
        </div>

        {/* 스타일 카드 그리드 */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {getFilteredStyles(selectedStyleCategory).map((style) => (
            <StyleCard
              key={style.id}
              style={style}
              userReactions={userReactions}
              handleReaction={handleReaction}
              addToMyStyles={addToMyStyles}
              addComment={addComment}
              comments={comments[style.id] || []}
              language={language}
              isAdded={addedStyles.has(style.id)}
              isCodeLoading={loadingStyles.has(style.id)}
            />
          ))}
        </div>
      </main>

      {/* 새 스타일 만들기 폼 */}
      <AnimatePresence>
        {isFormOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={handleCloseForm}
          >
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              transition={{ type: "spring", damping: 25, stiffness: 500 }}
              className="bg-gray-800 rounded-lg shadow-xl w-full max-w-2xl p-6"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">
                  {language === "ko" ? "새 스타일 만들기" : "Create New Style"}
                </h2>
                <button onClick={handleCloseForm} className="text-2xl">
                  &times;
                </button>
              </div>
              <input
                type="text"
                placeholder={language === "ko" ? "스타일 이름" : "Style Name"}
                value={newStyle.name}
                onChange={(e) =>
                  setNewStyle({ ...newStyle, name: e.target.value })
                }
                className="w-full p-3 mb-4 border rounded bg-gray-700 text-white"
                maxLength={20} // 이름 길이 제한
              />
              <p className="text-sm text-gray-400 text-right mb-2">
                {newStyle.name.length}/20
              </p>
              <div className="mb-4">
                <textarea
                  placeholder={language === "ko" ? "설명" : "Description"}
                  value={newStyle.description}
                  onChange={(e) =>
                    setNewStyle({ ...newStyle, description: e.target.value })
                  }
                  className="w-full p-3 mb-2 border rounded bg-gray-700 text-white h-24 custom-scrollbar"
                  maxLength={150} // 설명 길이 제한
                />
                <p className="text-sm text-gray-400 text-right mb-2">
                  {newStyle.description.length}/150
                </p>
                <p className="text-sm text-gray-400">
                  {language === "ko"
                    ? "예시: '갑자기 비가 와서 추워 🥺☁️☁️ 그런데 운치있는 빗소리를 들을 수 있으니까 완전 럭키비키아💛✨'"
                    : "Example: 'Suddenly it's raining and cold 🥺☁️☁️ But we can hear the atmospheric sound of rain, so it's totally lucky-vicky 💛✨'"}
                </p>
                <p className="text-sm text-gray-400 mt-2">
                  {language === "ko"
                    ? "설명: 재미있고 독특한 표현을 사용하여 스타일을 설명해주세요. 이렇게 작성된 설명은 주석 스타일에 반영됩니다."
                    : "Note: Describe your style using fun and unique expressions. This description will be reflected in the comment style."}
                </p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  {language === "ko" ? "카테고리 선택:" : "Select category:"}
                </label>
                <select
                  value={newStyle.category}
                  onChange={(e) =>
                    setNewStyle({
                      ...newStyle,
                      category: e.target.value as StyleCategory,
                    })
                  }
                  className="w-full p-3 border rounded bg-gray-700 text-white"
                >
                  {Object.entries(CategoryTranslations).map(([key, value]) => (
                    <option key={key} value={key}>
                      {CategoryEmojis[key as StyleCategory]} {value[language]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  {language === "ko" ? "언어 선택:" : "Select language:"}
                </label>
                <select
                  value={newStyle.language}
                  onChange={(e) =>
                    setNewStyle({
                      ...newStyle,
                      language: e.target.value as Language,
                    })
                  }
                  className="w-full p-3 border rounded bg-gray-700 text-white"
                >
                  <option value="ko">한국어</option>
                  <option value="en">English</option>
                </select>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-sm">
                  {language === "ko"
                    ? "오늘 남은 횟수: "
                    : "Today's Remaining: "}
                  {remainingStyles}/15
                </span>
                <motion.button
                  onClick={createStyle}
                  className={`bg-primary text-white px-6 py-3 rounded hover:bg-opacity-90 transition-colors ${
                    isCreating ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={isCreating}
                  animate={isCreating ? { opacity: [0.5, 1] } : {}}
                  transition={
                    isCreating ? { repeat: Infinity, duration: 0.8 } : {}
                  }
                >
                  {isCreating
                    ? language === "ko"
                      ? "생성 중..."
                      : "Creating..."
                    : language === "ko"
                    ? "스타일 만들기"
                    : "Create Style"}
                </motion.button>
              </div>

              {errorMessage && (
                <p className="text-red-500 mt-2">{errorMessage}</p>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* 새 스타일 만들기 버튼 (고정 위치) */}
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={handleCreateStyleClick}
        className="fixed bottom-6 right-6 bg-primary text-white w-14 h-14 rounded-full flex items-center justify-center text-2xl shadow-lg"
      >
        +
      </motion.button>

      {showSuccessAlert && (
        <SuccessAlert
          message={
            language === "ko"
              ? "스타일이 성공적으로 생성되었습니다!"
              : "Style created successfully!"
          }
          onClose={() => setShowSuccessAlert(false)}
        />
      )}

      {/* 남은 횟수 알림창 */}
      <AnimatePresence>
        {showLimitAlert && (
          <motion.div
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -100, opacity: 0 }}
            transition={{ type: "spring", stiffness: 500, damping: 30 }}
            className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-6 py-4 rounded-lg shadow-lg z-50 max-w-md w-full text-center"
          >
            <p className="mb-4 whitespace-pre-line">{limitAlertMessage}</p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setShowLimitAlert(false)}
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                {language === "ko" ? "닫기" : "Close"}
              </button>
              <Link to="/subscription">
                <button className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-4 rounded hover:from-purple-600 hover:to-pink-600 transition duration-300">
                  {language === "ko" ? "구독하기" : "Subscribe"}
                </button>
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default StyleStore;

import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import StyleStore from "./pages/StyleStore";
import Subscription from "./pages/Subscription";
import MyStyles from "./pages/MyStyles";
import LoginPage from "./pages/LoginPage"; // 중복 import 제거
import NicknameSetup from "./components/NicknameSetup";
import { useLanguage } from "./contexts/LanguageContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "./firebase";
import { getRedirectResult } from "firebase/auth";
import { ref, get } from "firebase/database";
import Terms from "./components/Terms"; // TermsAndPolicy 제거

function AppRoutes() {
  console.log("AppRoutes 렌더링");
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          // 로그인 성공
          const user = result.user;
          const nicknameRef = ref(database, `users/${user.uid}/nickname`);
          const nicknameSnapshot = await get(nicknameRef);

          if (nicknameSnapshot.exists()) {
            navigate("/");
          } else {
            navigate("/nickname-setup");
          }
        }
      } catch (error) {
        console.error("Authentication redirect error:", error);
      }
    };

    handleRedirectResult();
  }, [navigate]);

  useEffect(() => {
    if (user && !loading) {
      const checkNickname = async () => {
        const nicknameRef = ref(database, `users/${user.uid}/nickname`);
        const nicknameSnapshot = await get(nicknameRef);

        if (!nicknameSnapshot.exists()) {
          navigate("/nickname-setup");
        }
      };

      checkNickname();
    }
  }, [user, loading, navigate]);

  if (loading) {
    return <div>로딩 중...</div>;
  }

  return (
    <Routes>
      <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
      <Route
        path="/login"
        element={<LoginPage language={language} setLanguage={setLanguage} />}
      />
      <Route
        path="/nickname-setup"
        element={
          user ? (
            <NicknameSetup
              language={language}
              onComplete={() => navigate("/")}
            />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/style-store"
        element={user ? <StyleStore /> : <Navigate to="/login" />}
      />
      <Route
        path="/subscription"
        element={
          user ? <Subscription language={language} /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/my-styles"
        element={user ? <MyStyles /> : <Navigate to="/login" />}
      />
      <Route path="/terms" element={<Terms type="terms" />} />
      <Route path="/policy" element={<Terms type="policy" />} />
    </Routes>
  );
}

export default AppRoutes;

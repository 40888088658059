import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { Language } from "../types";

export const callHomeGetCodeLOLs = async (
  description: string,
  styleId: string,
  chat: string,
  styleLanguage: Language // 파라미터 이름 변경
) => {
  console.log("callHomeGetCodeLOLs 시작");
  const functions = getFunctions();
  const getcodelols = httpsCallable(functions, "getCodeLOLs");
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error("사용자가 인증되지 않았습니다.");
  }

  try {
    const idToken = await user.getIdToken();
    console.log("getcodelols 호출 전 데이터:", { description, styleId, chat, language: styleLanguage });
    const result = await getcodelols({ description, styleId, chat, language: styleLanguage, idToken });
    console.log("getcodelols 호출 후 응답:", result);
    const data = result.data as { commentedChat: string };
    console.log("파싱된 데이터:", data);
    return data.commentedChat;
  } catch (error) {
    console.error("getcodelols 함수 호출 중 오류 발생:", error);
    throw error;
  }
};

import React, { useEffect } from "react";
import { Language } from "../types";

interface TextSelectionProps {
  language: Language;
  onTextSelected: (
    selectedText: {
      original: string;
      commented: string;
      position: { top: number; left: number };
    } | null
  ) => void;
  containerRef: React.RefObject<HTMLElement>;
}

export const TextSelection: React.FC<TextSelectionProps> = ({
  language,
  onTextSelected,
  containerRef,
}) => {
  const handleTextSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      const range = selection.getRangeAt(0);
      
      // 선택된 텍스트가 지정된 컨테이너 내에 있는지 확인
      if (containerRef.current && containerRef.current.contains(range.commonAncestorContainer)) {
        const selectedContent = range.cloneContents();
        const tempDiv = document.createElement("div");
        tempDiv.appendChild(selectedContent);

        const originalCode =
          tempDiv.querySelector(".original-code")?.textContent || "";
        const commentedCode =
          tempDiv.querySelector(".commented-code")?.textContent || "";

        const selectedOriginal = selection.toString() || originalCode;
        const selectedCommented = selection.toString() || commentedCode;

        if (selectedOriginal || selectedCommented) {
          const rect = range.getBoundingClientRect();
          onTextSelected({
            original: selectedOriginal,
            commented: selectedCommented,
            position: {
              top: rect.bottom + window.scrollY,
              left: rect.left + window.scrollX,
            },
          });
          return;
        }
      }
    }
    onTextSelected(null);
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleTextSelection);
    return () => {
      document.removeEventListener("mouseup", handleTextSelection);
    };
  }, [containerRef]);

  return null;
};

export const koreanBadWords = [
  // 기존 비속어
  "시발",
  "씨발",
  "개새끼",
  "병신",
  "지랄",
  "씹",
  "존나",
  "좆",
  "새끼",
  "미친",

  // 추가 비속어 및 욕설
  "니미럴",
  "씨팔",
  "씨발놈",
  "씨발년",
  "개자식",
  "개같은",
  "개쓰레기",
  "개좆",
  "개새",
  "개짜증",
  "개족",
  "개돼지",
  "개씹",
  "개좇",
  "개떡",
  "개망나니",
  "개가튼",
  "개년",
  "개놈",
  "개똥",
  "개소리",
  "개씹덕",
  "개자지",
  "개보지",
  "개씹할",
  "개씹창",
  "개씹쓰레기",
  "개씹장",
  "개씹새끼",
  "개씹자식",
  "개씹좆",
  "개씹지랄",
  "개씹탱",
  "개씹핑",
  "개씹할",
  "개씹해",
  "개씹히",
  "개좆같은",
  "개좆밥",
  "개좆싸개",
  "개좆찐",
  "개좆털",
  "개좌식",
  "개주글",
  "개지랄",
  "개짜증나",
  "개찐따",
  "개찐찌",
  "개호로",
  "걸레",
  "게이",
  "귀두",
  "꼬추",
  "니기미",
  "니미",
  "니미랄",
  "니미럴",
  "니애미",
  "니에미",
  "닥쳐",
  "대가리",
  "대갈",
  "뒈져",
  "뒤져",
  "등신",
  "딸딸이",
  "또라이",
  "똘아이",
  "똘추",
  "띠발",
  "띠벌",
  "띠불",
  "띠팔",
  "메갈",
  "멍청이",
  "미친놈",
  "미친년",
  "미친새끼",
  "미친자식",
  "바보",
  "병cat",
  "병딱",
  "병맛",
  "병身",
  "보지",
  "불알",
  "빙신",
  "빠구리",
  "빠굴",
  "사까시",
  "상놈",
  "상년",
  "색히",
  "샹년",
  "섹스",
  "쉐리",
  "쉐이",
  "쉑",
  "쉨",
  "쌍놈",
  "쌍년",
  "쌍욕",
  "쌔끼",
  "썅",
  "쎄끼",
  "쓰레기",
  "씨방",
  "씨방새",
  "씨벌",
  "씨보랄",
  "씨부랄",
  "씨부럴",
  "씨블",
  "씨븽",
  "씨빨",
  "씨뽀랄",
  "씨앙",
  "씨파",
  "씨팍",
  "씨팔",
  "씹귀",
  "씹덕",
  "씹물",
  "씹보지",
  "씹새",
  "씹세",
  "씹쌔",
  "씹자지",
  "씹창",
  "씹탱",
  "씹펄",
  "씹할",
  "아가리",
  "아닥",
  "아헤가오",
  "애미",
  "애비",
  "앰창",
  "엄창",
  "엠창",
  "염병",
  "옘병",
  "왜놈",
  "왜년",
  "욕지거리",
  "육갑",
  "은년",
  "자지",
  "잡놈",
  "잡년",
  "저능아",
  "조까",
  "조낸",
  "조또",
  "조빠",
  "존나",
  "존니",
  "졸라",
  "좃",
  "좆같",
  "좆까",
  "좆나",
  "좆대",
  "좆도",
  "좆만",
  "좆밥",
  "좆뺑이",
  "좆씹",
  "좆찐",
  "좆털",
  "좇",
  "지랄",
  "지럴",
  "창녀",
  "창년",
  "캐년",
  "호로",
  "화냥년",
  "후레자식",
  "후장",
  "흑형",
  "흑누나",
  "흑형",
  "흑누나",

  // 성적인 단어
  "성관계",
  "자위",
  "음란",
  "성폭행",
  "강간",
  "떡치기",
  "씹팔",
  "씹할",
  "빨아",
  "빨어",
  "야동",
  "포르노",
  "성인용품",
  "오르가즘",
  "질내사정",
  "스와핑",
  "원조교제",
  "성매매",

  // 인종차별 및 혐오 표현
  "깜둥이",
  "짱깨",
  "짱개",
  "쪽바리",
  "쪽발이",
  "뚱녀",
  "김치녀",
  "된장녀",
  "맘충",
  "틀딱",
  "홍어",
  "빨갱이",
  "commie",
  "negro",
  "nigger",
  "chink",
  "gook",
  "jap",
  "faggot",
  "retard",
  "cripple",
  "negro",
  "nigga",
  "chink",
  "gook",
  "jap",
  "kike",
  "spic",
  "wetback",
  "beaner",
  "gringo",
  "yankee",
  "cracker",
  "honky",
  "slant-eye",
  "curry-muncher",
  "sand-nigger",
  "towelhead",
  "camel-jockey",
  "gypsy",
  "pikey",

  // 기타 부적절한 표현
  "자살",
  "살해",
  "테러",
  "폭탄",
  "총기",
  "마약",
  "코카인",
  "헤로인",
  "필로폰",
  "엑스터시",
  "대마초",
  "마리화나",
  "해시시",
  "아편",
  "모르핀",
  "메스암페타민",
  "케타민",
  "도핑",
];

// 성인용어 카테고리 (필요시 사용)
export const adultWords = [
  "섹스",
  "성관계",
  "야동",
  "포르노",
  "자위",
  "음란",
  "성인용품",
];

function levenshteinDistance(a: string, b: string): number {
  const matrix: number[][] = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j] + 1
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

function getNGrams(text: string, n: number): string[] {
  const ngrams: string[] = [];
  for (let i = 0; i <= text.length - n; i++) {
    ngrams.push(text.slice(i, i + n));
  }
  return ngrams;
}

function separateKoreanConsonantsAndVowels(text: string): string {
  const consonants = "ㄱㄲㄴㄷㄸㄹㅁㅂㅃㅅㅆㅇㅈㅉㅊㅋㅌㅍㅎ";
  const vowels = "ㅏㅐㅑㅒㅓㅔㅕㅖㅗㅘㅙㅚㅛㅜㅝㅞㅟㅠㅡㅢㅣ";
  let result = "";
  for (const char of text) {
    const code = char.charCodeAt(0) - 0xac00;
    if (code > -1 && code < 11172) {
      const consonantIndex = Math.floor(code / 588);
      const vowelIndex = Math.floor((code % 588) / 28);
      result += consonants[consonantIndex] + vowels[vowelIndex];
    } else {
      result += char;
    }
  }
  return result;
}

export function containsKoreanBadWord(text: string): boolean {
  const words = text.toLowerCase().split(/\s+/);
  const separatedText = separateKoreanConsonantsAndVowels(text.toLowerCase());

  for (const word of words) {
    // 1. 레벤슈타인 거리 기반 유사도 검사
    for (const badWord of koreanBadWords) {
      if (word.length >= 2 && badWord.length >= 2) {
        const distance = levenshteinDistance(word, badWord);
        const similarity = 1 - distance / Math.max(word.length, badWord.length);
        if (similarity > 0.8) {
          return true;
        }
      }
    }

    // 2. n-gram 분석
    const trigrams = getNGrams(word, 3);
    for (const trigram of trigrams) {
      if (koreanBadWords.some((badWord) => badWord.includes(trigram))) {
        return true;
      }
    }

    // 3. 자음/모음 분리 검사
    const separatedWord = separateKoreanConsonantsAndVowels(word);
    for (const badWord of koreanBadWords) {
      const separatedBadWord = separateKoreanConsonantsAndVowels(badWord);
      if (separatedWord.includes(separatedBadWord)) {
        return true;
      }
    }
  }

  // 4. 정규표현식을 이용한 패턴 매칭
  const badWordPatterns = koreanBadWords.map(
    (word) => new RegExp(word.split("").join("[\\s\\W]*"), "i")
  );
  if (badWordPatterns.some((pattern) => pattern.test(text))) {
    return true;
  }

  // 성인용어 검사 추가 (필요시 사용)
  if (adultWords.some((word) => text.toLowerCase().includes(word))) {
    return true;
  }

  return false;
}

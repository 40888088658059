import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { LanguageProvider, useLanguage } from "./contexts/LanguageContext"; // useLanguage 추가
import RightMenu from "./components/RightMenu"; // RightMenu import 추가
import { signOut } from "firebase/auth"; // signOut 함수 import 추가
import { auth } from "./firebase"; // auth import 추가

function App() {
  const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);

  const handleSignOut = () => {
    signOut(auth);
  };

  const handleCodeLOLsLabClick = () => {
    // CodeLOLs Lab 클릭 핸들러 로직 추가
  };

  const handleEmailClick = (type: "idea" | "inquiry") => {
    // 이메일 클릭 핸들러 로직 추가
  };

  return (
    <LanguageProvider>
      <Router>
        <AppRoutes />
        <RightMenuWrapper
          isOpen={isRightMenuOpen}
          onClose={() => setIsRightMenuOpen(false)}
          signOut={handleSignOut}
          handleCodeLOLsLabClick={handleCodeLOLsLabClick}
          handleEmailClick={handleEmailClick}
        />
      </Router>
    </LanguageProvider>
  );
}

const RightMenuWrapper: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  signOut: () => void;
  handleCodeLOLsLabClick: () => void;
  handleEmailClick: (type: "idea" | "inquiry") => void;
}> = ({
  isOpen,
  onClose,
  signOut,
  handleCodeLOLsLabClick,
  handleEmailClick,
}) => {
  const { language, setLanguage } = useLanguage();

  return (
    <RightMenu
      isOpen={isOpen}
      onClose={onClose}
      signOut={signOut}
      language={language}
      setLanguage={setLanguage}
      handleCodeLOLsLabClick={handleCodeLOLsLabClick}
      handleEmailClick={handleEmailClick}
    />
  );
};

export default App;

import React, { useState, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Style,
  Comment,
  Language,
  CategoryEmojis,
  CategoryTranslations,
  ReactionEmojis,
  ReactionTranslations,
} from "../types";
import { escapeHtml } from "../utils/stringUtils";

interface StyleCardProps {
  style: Style;
  userReactions: any;
  handleReaction: (
    style: Style,
    reactionType: "like" | "funny" | "interesting" | "risky"
  ) => void;
  addToMyStyles: (style: Style) => void;
  addComment: (styleId: string, comment: string) => void;
  comments: Comment[];
  language: Language;
  isAdded: boolean;
  removeStyle?: () => void;
  showRemoveButton?: boolean;
  removeText?: string;
  showRemoveButtonOnTop?: boolean;
  isCodeLoading: boolean;
}

// 스타일 추가
const scrollbarStyles = `
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #2d3748;
    border-radius: 4px;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #4a5568;
    border-radius: 4px;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #718096;
  }
`;

// 간단한 구문 강조 함수 수정
const highlightCode = (code: string) => {
  if (typeof code !== "string") {
    console.error("Invalid code type:", typeof code, code);
    return { __html: "" };
  }
  const escapedCode = escapeHtml(code);
  const highlightedCode = escapedCode
    .split("\n")
    .map((line, index) => {
      // 주석 강조
      if (line.trim().startsWith("//")) {
        return `<span style="color: #10b981; font-weight: bold;">${line}</span>`;
      }
      // 여기에 다른 구문 강조 규칙을 추가할 수 있습니다.
      return line;
    })
    .join("\n");
  return { __html: highlightedCode };
};

const StyleCard: React.FC<StyleCardProps> = ({
  style,
  userReactions,
  handleReaction,
  addToMyStyles,
  addComment,
  comments,
  language,
  isAdded,
  removeStyle,
  showRemoveButton = false,
  removeText,
  showRemoveButtonOnTop = false,
  isCodeLoading,
}) => {
  const [commentText, setCommentText] = useState("");
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const commentsPerPage = 8;

  const handleCommentSubmit = () => {
    if (commentText.trim()) {
      addComment(style.id, commentText);
      setCommentText("");
    }
  };

  const toggleCommentModal = () => {
    setIsCommentModalOpen(!isCommentModalOpen);
  };

  // 사용자별 고유 색상 생성 (컴포넌트 외부로 이동하고 useMemo 사용)
  const userColors = useMemo(() => {
    const colors = {};
    const generateColor = (username: string) => {
      let hash = 0;
      for (let i = 0; i < username.length; i++) {
        hash = username.charCodeAt(i) + ((hash << 5) - hash);
      }
      const hue = hash % 360;
      return `hsl(${hue}, 70%, 70%)`;
    };

    comments.forEach((comment) => {
      if (!colors[comment.createdBy]) {
        colors[comment.createdBy] = generateColor(comment.createdBy);
      }
    });
    return colors;
  }, [comments]);

  const indexOfLastComment = currentPage * commentsPerPage;
  const indexOfFirstComment = indexOfLastComment - commentsPerPage;
  const currentComments = comments.slice(
    indexOfFirstComment,
    indexOfLastComment
  );
  const totalPages = Math.ceil(comments.length / commentsPerPage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // 언어에 따른 텍스트 설정
  const modalTexts = {
    comments: language === "ko" ? "댓글" : "Comments",
    addComment: language === "ko" ? "댓글 추가..." : "Add a comment...",
    post: language === "ko" ? "게시" : "Post",
  };

  const highlightedCode = useMemo(() => {
    if (typeof style.code !== "string") {
      console.error("Invalid code type:", typeof style.code, style.code);
      return { __html: "" };
    }
    return highlightCode(style.code);
  }, [style.code]);

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-xl h-full flex flex-col relative">
      {/* 스타일 태그 추가 */}
      <style>{scrollbarStyles}</style>

      <div className="flex justify-between items-start mb-3">
        <div>
          <h3 className="text-xl font-semibold text-white">{style.name}</h3>
          <span className="text-sm text-gray-400">
            {CategoryEmojis[style.category]}{" "}
            {CategoryTranslations[style.category][language]}
          </span>
        </div>
        {showRemoveButtonOnTop && showRemoveButton ? (
          <button
            onClick={removeStyle}
            className="px-3 py-1 rounded text-sm bg-red-500 hover:bg-red-600 text-white"
          >
            {removeText || (language === "ko" ? "삭제" : "Remove")}
          </button>
        ) : (
          !showRemoveButton && (
            <button
              onClick={() => addToMyStyles(style)}
              className={`px-3 py-1 rounded text-sm ${
                isAdded
                  ? "bg-green-500 text-white"
                  : "bg-blue-500 hover:bg-blue-600 text-white"
              }`}
              disabled={isAdded}
            >
              {isAdded
                ? language === "ko"
                  ? "추가됨"
                  : "Added"
                : language === "ko"
                ? "추가"
                : "Add"}
            </button>
          )
        )}
      </div>
      <p className="text-xs text-gray-400 mb-2">
        Created by: {style.creatorNickname || "Unknown"}
      </p>
      <p className="text-sm text-gray-300 mb-3">{style.description}</p>
      <div className="bg-gray-700 p-3 rounded-lg mb-4 h-40 overflow-y-auto custom-scrollbar">
        {isCodeLoading ? (
          <div className="flex items-center justify-center h-full">
            <p>{language === "ko" ? "코드 로딩 중..." : "Loading code..."}</p>
          </div>
        ) : (
          <pre className="text-xs text-gray-300 whitespace-pre-wrap break-words">
            <code dangerouslySetInnerHTML={highlightedCode}></code>
          </pre>
        )}
      </div>
      <div className="bg-gray-700 p-4 rounded-lg mb-4 flex-grow">
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-3">
            {(["like", "funny", "interesting", "risky"] as const).map(
              (reactionType) => (
                <button
                  key={reactionType}
                  onClick={() => handleReaction(style, reactionType)}
                  className={`p-2 rounded-full ${
                    userReactions[style.id]?.[reactionType]
                      ? "bg-primary text-white"
                      : "bg-gray-600 text-gray-300 hover:bg-gray-500"
                  } transition-colors duration-200`}
                  title={ReactionTranslations[reactionType][language]}
                >
                  {ReactionEmojis[reactionType]}
                  <span className="ml-1 text-xs">
                    {style.reactions[reactionType] || 0}
                  </span>
                </button>
              )
            )}
          </div>
          <div className="flex space-x-2">
            {showRemoveButton && !showRemoveButtonOnTop && (
              <button
                onClick={removeStyle}
                className="px-4 py-2 rounded bg-red-500 hover:bg-red-600 text-white"
              >
                {removeText || (language === "ko" ? "삭제" : "Remove")}
              </button>
            )}
            <button
              onClick={toggleCommentModal}
              className="px-4 py-2 rounded bg-gray-600 hover:bg-gray-500 text-white"
            >
              💬 {comments.length}
            </button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isCommentModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={toggleCommentModal}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-gray-800 rounded-lg w-full max-w-4xl h-[80vh] overflow-hidden shadow-2xl flex"
              onClick={(e) => e.stopPropagation()}
            >
              {/* 좌측: 스타일 코드 */}
              <div className="w-1/2 p-4 bg-gray-900 border-r border-gray-700 overflow-y-auto custom-scrollbar">
                <h3 className="text-lg font-semibold text-white mb-3">
                  {style.name}
                </h3>
                <pre className="text-sm text-gray-300 whitespace-pre-wrap bg-gray-800 p-3 rounded-lg border border-gray-700">
                  <code dangerouslySetInnerHTML={highlightedCode}></code>
                </pre>
              </div>

              {/* 우측: 댓글 목록 및 입력 */}
              <div className="w-1/2 flex flex-col bg-gray-800">
                {/* 헤더 */}
                <div className="border-b border-gray-700 px-4 py-3 flex items-center justify-between">
                  <h3 className="text-lg font-semibold text-white">
                    {modalTexts.comments}
                  </h3>
                  <button
                    onClick={toggleCommentModal}
                    className="text-gray-400 hover:text-gray-200"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                {/* 댓글 목록 */}
                <div className="flex-grow overflow-y-auto p-4 custom-scrollbar">
                  {currentComments.map((comment, index) => (
                    <div key={index} className="mb-4 flex">
                      <div className="flex-shrink-0 mr-3">
                        <div
                          className="w-8 h-8 rounded-full flex items-center justify-center text-white text-sm font-semibold"
                          style={{
                            backgroundColor: userColors[comment.createdBy],
                          }}
                        >
                          {comment.createdBy.charAt(0).toUpperCase()}
                        </div>
                      </div>
                      <div className="flex-grow">
                        <p className="text-sm text-white">
                          <span
                            className="font-semibold mr-2"
                            style={{ color: userColors[comment.createdBy] }}
                          >
                            {comment.createdBy}
                          </span>
                          {comment.text}
                        </p>
                        <p className="text-xs text-gray-400 mt-1">
                          {new Date(comment.createdAt).toLocaleDateString(
                            language === "ko" ? "ko-KR" : "en-US"
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* 페이지네이션 */}
                <div className="flex justify-center items-center py-2 border-t border-gray-700">
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i}
                      onClick={() => paginate(i + 1)}
                      className={`mx-1 px-3 py-1 rounded ${
                        currentPage === i + 1
                          ? "bg-blue-600 text-white"
                          : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                      }`}
                    >
                      {i + 1}
                    </button>
                  ))}
                </div>

                {/* 댓글 입력 */}
                <div className="border-t border-gray-700 px-4 py-3">
                  <div className="flex items-center">
                    <input
                      type="text"
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                      placeholder={modalTexts.addComment}
                      className="flex-grow bg-gray-700 text-white placeholder-gray-400 px-3 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button
                      onClick={handleCommentSubmit}
                      className="ml-2 bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors duration-200 disabled:opacity-50"
                      disabled={!commentText.trim()}
                    >
                      {modalTexts.post}
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default StyleCard;

import React from "react";
import { Style, Language } from "../types";

interface StylePreviewProps {
  style: Style;
  language: Language;
}

const StylePreview: React.FC<StylePreviewProps> = ({ style, language }) => {
  return (
    <div className="fixed top-0 left-72 w-64 h-full bg-gray-700 p-4 overflow-y-auto">
      <h3 className="text-xl font-bold text-white mb-4">{style.name}</h3>
      <p className="text-gray-300 mb-4">{style.description}</p>
      {style.example && (
        <div className="bg-gray-800 p-4 rounded">
          <h4 className="text-lg font-semibold text-white mb-2">
            {language === "ko" ? "예시" : "Example"}
          </h4>
          <pre className="text-sm text-gray-300 whitespace-pre-wrap">
            {style.example}
          </pre>
        </div>
      )}
    </div>
  );
};

export default StylePreview;

import React from "react";
import { Link } from "react-router-dom";
import { Language } from "../types";
import LanguageSelector from "./LanguageSelector";

interface RightMenuProps {
  isOpen: boolean;
  onClose: () => void;
  language: Language;
  setLanguage: (lang: Language) => void;
  handleCodeLOLsLabClick: () => void;
  handleEmailClick: (type: "idea" | "inquiry") => void;
  signOut: () => void;
}

export const RightMenu: React.FC<RightMenuProps> = ({
  isOpen,
  onClose,
  language,
  setLanguage,
  handleCodeLOLsLabClick,
  handleEmailClick,
  signOut,
}) => {
  return (
    <div
      className={`fixed top-0 right-0 w-64 h-full bg-surface shadow-lg transform transition-transform duration-300 ease-in-out z-30 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="flex flex-col h-full p-4">
        <div className="flex justify-between items-center mb-4">
          <LanguageSelector language={language} setLanguage={setLanguage} />
          <button onClick={onClose} className="text-2xl">
            ✕
          </button>
        </div>
        <Link
          to="/style-store"
          className="bg-primary text-white px-4 py-2 rounded mb-4 text-center"
        >
          {language === "ko" ? "스타일 스토어 🏪" : "Style Store 🏪"}
        </Link>
        <a
          href="https://buymeacoffee.com/kr2idiots"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-yellow-400 text-black px-4 py-2 rounded mb-4 text-center flex items-center transition-transform transform hover:scale-110 hover:rotate-3"
        >
          <span className="mr-2">☕</span> Buy me a coffee
        </a>
        <Link
          to="/my-styles"
          className="bg-secondary text-white px-4 py-2 rounded mb-4 text-center"
        >
          {language === "ko" ? "스타일 저장소 💾" : "Style Storage 💾"}
        </Link>
        <div className="flex-grow"></div>
        <button
          onClick={handleCodeLOLsLabClick}
          className="w-full bg-primary text-white px-4 py-2 rounded text-center mb-4"
        >
          CodeLOLs Lab 🧪
        </button>
        <div className="space-y-2 mb-4">
          <button
            onClick={() => handleEmailClick("inquiry")}
            className="w-full bg-secondary text-white px-4 py-2 rounded text-center"
          >
            {language === "ko" ? "피드백 💌" : "Feedback 💌"}
          </button>
          <button
            onClick={signOut}
            className="w-full bg-red-500 text-white px-4 py-2 rounded mt-2"
          >
            {language === "ko" ? "로그아웃 🚪" : "Sign Out 🚪"}
          </button>
        </div>

        {/* 이용약관과 개인정보 처리방침 버튼 수정 */}
        <div className="flex justify-between mt-4">
          <Link
            to="/terms"
            className="text-sm text-white-600 hover:text-gray-800"
          >
            {language === "ko" ? "이용약관" : "Terms of Service"}
          </Link>
          <Link
            to="/policy"
            className="text-sm text-white-600 hover:text-gray-800"
          >
            {language === "ko" ? "개인정보 처리방침" : "Privacy Policy"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RightMenu;

import React, { useState, useEffect } from "react";
import { auth, database } from "../firebase";
import { ref, set } from "firebase/database";
import { Language } from "../types";
import { containsKoreanBadWord } from "../utils/koreanBadWords";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { get, query, orderByChild, equalTo } from "firebase/database";

// 비속어 목록 (영어와 한국어)
const badWords = [
  "시발",
  "씨발",
  "개새끼",
  "병신",
  "지랄",
  "씹",
  "존나",
  "좆",
  "새끼",
];

// 비속어 체크 함수
const containsBadWord = (text: string): boolean => {
  const lowerText = text.toLowerCase();
  return badWords.some((word) => lowerText.includes(word));
};

interface NicknameSetupProps {
  language: Language;
  onComplete: () => void;
}

const NicknameSetup: React.FC<NicknameSetupProps> = ({
  language,
  onComplete,
}) => {
  const [nickname, setNickname] = useState("");
  const [error, setError] = useState("");
  // filter 상태 제거

  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) {
      navigate("/login");
    }
  }, [user, loading, navigate]);

  const handleNicknameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNickname(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    if (!nickname.trim()) {
      setError(
        language === "ko"
          ? "닉네임을 입력해 주세요."
          : "Please enter a nickname."
      );
      return;
    }

    if (
      containsKoreanBadWord(nickname.trim()) ||
      containsBadWord(nickname.trim())
    ) {
      setError(
        language === "ko"
          ? "부적절한 단어가 포함되어 있습니다."
          : "The nickname contains inappropriate words."
      );
      return;
    }

    const nicknameQuery = query(
      ref(database, "users"),
      orderByChild("nickname"),
      equalTo(nickname.trim())
    );

    try {
      const snapshot = await get(nicknameQuery);
      if (snapshot.exists()) {
        setError(
          language === "ko"
            ? "누군가 당신의 아이디어를 훔쳐갔군요! 다른 닉네임을 시도해 보세요."
            : "Someone has already stolen your idea! Try another nickname."
        );
        return;
      }
    } catch (error) {
      console.error("닉네임 중복 확인 중 오류 발생:", error);
      setError(
        language === "ko"
          ? "닉네임 중복 확인 중 오류가 발생했습니다. 다시 시도해 주세요."
          : "An error occurred while checking the nickname. Please try again."
      );
      return;
    }

    const user = auth.currentUser;
    if (user) {
      try {
        const nicknameRef = ref(database, `users/${user.uid}/nickname`);
        await set(nicknameRef, nickname.trim());
        onComplete();
      } catch (error) {
        console.error("닉네임 저장 중 오류 발생:", error);
        setError(
          language === "ko"
            ? "닉네임 저장 중 오류가 발생했습니다. 다시 시도해 주세요."
            : "An error occurred while saving the nickname. Please try again."
        );
      }
    }
  };

  const warningMessage = {
    ko: "주의: 이 닉네임은 영원히 당신과 함께할 거예요! 마치 타투처럼요. 😱 신중게 골라주세요!",
    en: "Warning: This nickname will stick with you forever! Like a tattoo, but digital. 😱 Choose wisely!",
  };

  const placeholderText = {
    ko: "불후의 명닉네임을 입력하세요...",
    en: "Enter your legendary nickname...",
  };

  const buttonText = {
    ko: "운명을 결정하다",
    en: "Seal My Fate",
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  if (!user) {
    return null; // 로그인하지 않은 사용자는 아무것도 렌더링하지 않음
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-8 rounded-lg max-w-md w-full text-white">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          {language === "ko" ? "닉네임 설정" : "Set Your Nickname"}
        </h2>
        <p className="text-yellow-300 mb-4 text-center">
          {warningMessage[language]}
        </p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={nickname}
            onChange={handleNicknameChange}
            placeholder={placeholderText[language]}
            className="w-full p-2 mb-4 border rounded bg-gray-700 text-white custom-scrollbar"
          />
          {/* 필터 입력 필드 제거 */}
          {error && <p className="text-red-500 mb-2">{error}</p>}
          <button
            type="submit"
            className="w-full bg-primary text-white px-4 py-2 rounded hover:bg-secondary transition-colors"
          >
            {buttonText[language]}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NicknameSetup;

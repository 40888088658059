export const CodeCapture = {
  copyToClipboard: (text: string) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  },

  captureCode: (originalCode: string, commentedCode: string) => {
    const lines = commentedCode.split("\n").length;
    if (lines <= 10) {
      CodeCapture.captureShortCode(originalCode, commentedCode);
    } else {
      CodeCapture.captureLongCode(originalCode, commentedCode);
    }
  },

  captureShortCode: (originalCode: string, commentedCode: string) => {
    const captureElement = document.createElement("div");
    captureElement.innerHTML = `
        <div style="background-color: #1f2937; color: #f3f4f6; padding: 20px; font-family: 'Courier New', monospace; width: 600px;">
          <h1 style="color: #6366f1; text-align: center; font-size: 24px; margin-bottom: 10px;">CodeLOLs.com</h1>
          <div style="background-color: #374151; padding: 15px; border-radius: 8px; margin-bottom: 10px;">
            <pre style="margin: 0; white-space: pre-wrap; font-size: 14px;">${CodeCapture.highlightComments(
              commentedCode
            )}</pre>
          </div>
          <div style="display: flex; justify-content: space-between; font-size: 12px;">
            <span>Generated by CodeLOLs.com</span>
            <span>${new Date().toLocaleString()}</span>
          </div>
        </div>
      `;
    document.body.appendChild(captureElement);
    import("html-to-image").then((htmlToImage) => {
      htmlToImage
        .toPng(captureElement)
        .then((dataUrl: string) => {
          const link = document.createElement("a");
          link.download = "codelols-short-capture.png";
          link.href = dataUrl;
          link.click();
        })
        .catch((error: Error) => {
          console.error("Error capturing code:", error);
        })
        .finally(() => {
          document.body.removeChild(captureElement);
        });
    });
  },

  captureLongCode: (originalCode: string, commentedCode: string) => {
    const captureElement = document.createElement("div");
    captureElement.innerHTML = `
        <div style="background-color: #1f2937; color: #f3f4f6; padding: 20px; font-family: Arial, sans-serif; width: 800px;">
          <h1 style="color: #6366f1; text-align: center; font-size: 36px; margin-bottom: 20px;">CodeLOLs.com</h1>
          <div style="display: flex; justify-content: space-between;">
            <div style="width: 48%;">
              <h3 style="color: #8b5cf6; font-size: 24px; margin-bottom: 10px;">Original Code</h3>
              <pre style="background-color: #374151; padding: 15px; border-radius: 8px; white-space: pre-wrap; font-size: 14px;">${originalCode}</pre>
            </div>
            <div style="width: 48%;">
              <h3 style="color: #8b5cf6; font-size: 24px; margin-bottom: 10px;">Generated Code</h3>
              <pre style="background-color: #374151; padding: 15px; border-radius: 8px; white-space: pre-wrap; font-size: 14px;">${CodeCapture.highlightComments(
                commentedCode
              )}</pre>
            </div>
          </div>
        </div>
      `;
    document.body.appendChild(captureElement);
    import("html2canvas").then((html2canvas) => {
      html2canvas
        .default(captureElement, {
          scale: 2,
          width: 800,
          height: captureElement.offsetHeight,
        })
        .then((canvas) => {
          const link = document.createElement("a");
          link.download = "codelols-long-capture.png";
          link.href = canvas.toDataURL();
          link.click();
          document.body.removeChild(captureElement);
        });
    });
  },

  highlightComments: (code: string) => {
    return code.replace(
      /(\/\/.*)/g,
      '<span style="color: #10b981; font-weight: bold;">$1</span>'
    );
  },

  scrollbarStyles: `
      .custom-scrollbar::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #2d3748;
        border-radius: 4px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #4a5568;
        border-radius: 4px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #718096;
      }
    `,
};

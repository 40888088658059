export type Language = "ko" | "en";

export type CommentMode =
  | "memeGangnam"
  | "memeDoge"
  | "celebPsy"
  | "celebBTS"
  | "dialectBusan"
  | "dialectJeju"
  | "movieMatrix"
  | "animeNaruto"
  | "gameMinecraft"
  | "bookHarryPotter"
  | "memeRickRoll"
  | "celebTrump"
  | "dialectTexan"
  | "movieStarWars"
  | "animeOnePiece"
  | "gameFortnight"
  | "dramaGoblin"
  | "foodKimchi"
  | "celebElonMusk"
  | "dialectCockney"
  | "tvTheOffice";

export interface StyleInfo {
  value: CommentMode;
  label: string;
  icon: string;
  category: string;
  prompt: string;
  language: Language;
}

export interface Style {
  id: string;
  name: string;
  description: string;
  code: string;
  createdBy: string;
  creatorNickname: string;
  createdAt: number;
  reactions: {
    like: number;
    funny: number;
    interesting: number;
    risky: number;
  };
  comments: Comment[];
  addCount: number;
  hotScore?: number; // hotScore 속성 추가
  category: StyleCategory;
  language: Language; // 이 줄을 추가합니다
  example?: string; // example을 선택적 속성으로 추가
}

export interface UserReactions {
  [styleId: string]: {
    like?: boolean;
    funny?: boolean;
    interesting?: boolean;
    risky?: boolean;
  };
}

export interface Comment {
  id: string;
  text: string;
  createdBy: string;
  createdAt: number;
}

export interface ChatMessage {
  id: string;
  originalCode: string;
  commentedCode: string;
  timestamp: Date;
}

export type StyleCategory = "Meme" | "Celebrity" | "MovieTV" | "Game" | "Other";

export const CategoryEmojis: Record<StyleCategory, string> = {
  Meme: "😂",
  Celebrity: "🌟",
  MovieTV: "🎬",
  Game: "🎮",
  Other: "🔮",
};

export const ReactionEmojis = {
  like: "👍",
  funny: "😂",
  interesting: "🤔",
  risky: "😱",
};

export const CategoryTranslations: Record<
  StyleCategory,
  Record<Language, string>
> = {
  Meme: { ko: "밈", en: "Meme" },
  Celebrity: { ko: "유명인", en: "Celebrity" },
  MovieTV: { ko: "영화/TV", en: "Movie/TV" },
  Game: { ko: "게임", en: "Game" },
  Other: { ko: "기타", en: "Other" },
};

export const ReactionTranslations: Record<
  keyof typeof ReactionEmojis,
  Record<Language, string>
> = {
  like: { ko: "좋아요", en: "Like" },
  funny: { ko: "웃겨요", en: "Funny" },
  interesting: { ko: "흥미로워요", en: "Interesting" },
  risky: { ko: "상사에게 혼날 것 같아요", en: "Might get scolded by boss" },
};

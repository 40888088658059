import { useState, useEffect, useCallback, useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../firebase";
import { ref, get, set, onValue, off } from "firebase/database";
import { CommentMode, ChatMessage, Style } from "../types";
import { callHomeGetCodeLOLs } from "../utils/homeGetCodeLOLs";
import { useLanguage } from '../contexts/LanguageContext';

export const useHomeLogic = () => {
  const [user, loading] = useAuthState(auth);
  const { language, setLanguage } = useLanguage();
  const [dailyUsage, setDailyUsage] = useState({
    count: 0,
    date: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [mode, setMode] = useState<CommentMode>("memeGangnam");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
  const [showCodeLOLsLabInfo, setShowCodeLOLsLabInfo] = useState(false);
  const [showNicknameSetup, setShowNicknameSetup] = useState(false);
  const [userStyles, setUserStyles] = useState<Style[]>([]);
  const [selectedStyle, setSelectedStyle] = useState<Style | null>(null);

  // 언어 설정이 변경될 때마다 로컬 스토리지에 저장
  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  const loadingMessages = useMemo(
    () => ({
      ko: [
        "코드에 밈 첨가중...",
        "AI에게 유머 훈련중...",
        "함수에 개그 주입중...",
        "변수명 개그로 바꾸는 중...",
        "주석에 유머 넣는 중...",
      ],
      en: [
        "Adding memes to code...",
        "Training AI in humor...",
        "Injecting jokes into functions...",
        "Renaming variables with puns...",
        "Inserting humor into comments...",
      ],
    }),
    []
  );

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        const messages = loadingMessages[language];
        const randomMessage =
          messages[Math.floor(Math.random() * messages.length)];
        setLoadingMessage(randomMessage);
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [isLoading, language, loadingMessages]);

  useEffect(() => {
    if (user) {
      const userRef = ref(database, `users/${user.uid}/dailyUsage`);
      get(userRef).then((snapshot) => {
        const data = snapshot.val();
        const currentDate = new Date().toDateString();
        if (data) {
          if (data.date !== currentDate) {
            set(userRef, {
              count: 0,
              date: currentDate,
            });
            setDailyUsage({ count: 0, date: currentDate });
          } else {
            setDailyUsage(data);
          }
        } else {
          set(userRef, {
            count: 0,
            date: currentDate,
          });
          setDailyUsage({ count: 0, date: currentDate });
        }
      });
    }
  }, [user]);

  const handleCodeSubmit = useCallback(
    async (submittedChat: string) => {
      if (chatMessages.some((msg) => msg.originalCode === submittedChat)) {
        return;
      }

      if (dailyUsage.count >= 30) {
        alert(
          language === "ko"
            ? "오늘의 생성 횟수를 초과했습니다. 내일 다시 시도해 주세요."
            : "You have exceeded today's generation limit. Please try again tomorrow."
        );
        return;
      }

      const newMessage: ChatMessage = {
        id: Date.now().toString(),
        originalCode: submittedChat,
        commentedCode: "",
        timestamp: new Date(),
      };

      setChatMessages((prevMessages) => [...prevMessages, newMessage]);

      setIsLoading(true);
      setLoadingMessage(
        loadingMessages[language][
          Math.floor(Math.random() * loadingMessages[language].length)
        ]
      );

      try {
        if (selectedStyle) {
          const commentedCode = await callHomeGetCodeLOLs(
            selectedStyle.description,
            selectedStyle.id,
            submittedChat,
            selectedStyle.language
          );

          setChatMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === newMessage.id
                ? { ...msg, commentedCode: commentedCode }
                : msg
            )
          );

          const userRef = ref(database, `users/${user?.uid}/dailyUsage`);
          const newCount = dailyUsage.count + 1;
          set(userRef, {
            count: newCount,
            date: dailyUsage.date,
          });
          setDailyUsage({
            count: newCount,
            date: dailyUsage.date,
          });
        } else {
          throw new Error(
            language === "ko"
              ? "스타일이 선택되지 않았습니다."
              : "No style selected."
          );
        }
      } catch (error) {
        console.error("Error generating commented code:", error);
        alert(
          language === "ko"
            ? "코드 생성 중 오류가 발생했습니다. 스타일이 선택되었는지 확인해 주세요."
            : "An error occurred while generating the code. Please make sure a style is selected."
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      language,
      chatMessages,
      loadingMessages,
      dailyUsage,
      user,
      selectedStyle,
    ]
  );

  useEffect(() => {
    if (user) {
      const userStylesRef = ref(database, `users/${user.uid}/styles`);
      onValue(userStylesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const stylesArray = Object.entries(data).map(
            ([id, value]: [string, any]) => ({
              id,
              ...value,
            })
          );
          setUserStyles(stylesArray);
        }
      });

      return () => {
        off(userStylesRef);
      };
    }
  }, [user]);

  const handleCodeLOLsLabClick = () => {
    setShowCodeLOLsLabInfo(true);
  };

  const handleCloseCodeLOLsLabInfo = () => {
    setShowCodeLOLsLabInfo(false);
  };

  const handleEmailClick = (type: "idea" | "inquiry") => {
    const subject =
      language === "ko"
        ? type === "idea"
          ? "CodeLOLs 아이디어 제안"
          : "CodeLOLs 문의 및 개선사항"
        : type === "idea"
        ? "CodeLOLs Idea Suggestion"
        : "CodeLOLs Inquiry and Improvement";
    window.location.href = `mailto:kr2idiots@gmail.com?subject=${encodeURIComponent(
      subject
    )}`;
  };

  const handleSubscribe = async (): Promise<boolean> => {
    if (user && user.email) {
      const subscriptionRef = ref(database, `subscriptions/${user.uid}`);
      try {
        await set(subscriptionRef, { email: user.email });
        return true;
      } catch (error) {
        console.error("Subscription error:", error);
        alert(
          language === "ko"
            ? "구독 중 오류가 발생했습니다."
            : "An error occurred during subscription."
        );
        return false;
      }
    } else {
      alert(
        language === "ko"
          ? "구독하려면 로그인이 필요합니다."
          : "Please log in to subscribe."
      );
      return false;
    }
  };

  useEffect(() => {
    if (user && !loading) {
      const userRef = ref(database, `users/${user.uid}`);
      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.val();
          if (!userData.nickname) {
            setShowNicknameSetup(true);
          }
        } else {
          setShowNicknameSetup(true);
        }
      });
    }
  }, [user, loading]);

  return {
    user,
    loading,
    dailyUsage,
    isLoading,
    loadingMessage,
    chatMessages,
    mode,
    language,
    isMenuOpen,
    isRightMenuOpen,
    showCodeLOLsLabInfo,
    showNicknameSetup,
    userStyles,
    selectedStyle,
    setIsMenuOpen,
    setIsRightMenuOpen,
    setLanguage,
    setMode,
    setSelectedStyle,
    handleCodeSubmit,
    handleCodeLOLsLabClick,
    handleCloseCodeLOLsLabInfo,
    handleEmailClick,
    handleSubscribe,
    setShowNicknameSetup,
  };
};

import React, { useRef, useState } from "react";
import { auth } from "../firebase";
import CodeInput from "../components/CodeInput";
import SlideMenu from "../components/SlideMenu";
import LoadingUI from "../components/LoadingUI";
import { Header } from "../components/Header";
import { RightMenu } from "../components/RightMenu";
import { ChatMessages } from "../components/ChatMessages";
import CodeLOLsLabInfo from "../components/CodeLOLsLabInfo";
import NicknameSetup from "../components/NicknameSetup";
import { useHomeLogic } from "../hooks/useHomeLogic";
import { CodeCapture } from "../components/CodeCapture";
import { TextSelection } from "../components/TextSelection";
import DragCapture from "../components/DragCapture";
import DOMPurify from "dompurify";

const Home: React.FC = () => {
  const {
    user,
    loading,
    dailyUsage,
    isLoading,
    loadingMessage,
    chatMessages,
    language,
    isMenuOpen,
    isRightMenuOpen,
    showCodeLOLsLabInfo,
    showNicknameSetup,
    mode,
    selectedStyle,
    setIsMenuOpen,
    setIsRightMenuOpen,
    setLanguage,
    setMode,
    setSelectedStyle,
    handleCodeSubmit,
    handleCodeLOLsLabClick,
    handleCloseCodeLOLsLabInfo,
    handleEmailClick,
    handleSubscribe,
    setShowNicknameSetup,
  } = useHomeLogic();

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [selectedText, setSelectedText] = useState<{
    original: string;
    commented: string;
    position: { top: number; left: number };
  } | null>(null);

  const highlightComments = (code: string) => {
    const highlightedCode = code
      .split("\n")
      .map((line, index) => {
        if (line.trim().startsWith("//") && line.trim().endsWith("//")) {
          return `<span key=${index} style="color: #10b981;">${DOMPurify.sanitize(
            line
          )}</span>`;
        }
        return `<span key=${index}>${DOMPurify.sanitize(line)}</span>`;
      })
      .join("<br>");

    return DOMPurify.sanitize(highlightedCode, {
      USE_PROFILES: { html: true },
    });
  };

  if (loading) {
    return (
      <LoadingUI message={language === "ko" ? "로딩 중..." : "Loading..."} />
    );
  }

  if (!user) {
    return (
      <NicknameSetup
        language={language}
        onComplete={() => setShowNicknameSetup(false)}
      />
    );
  }

  if (showNicknameSetup) {
    return (
      <NicknameSetup
        language={language}
        onComplete={() => setShowNicknameSetup(false)}
      />
    );
  }

  return (
    <div className="flex flex-col h-screen bg-background text-text">
      <Header
        setIsMenuOpen={setIsMenuOpen}
        setIsRightMenuOpen={setIsRightMenuOpen}
        selectedStyle={selectedStyle} // 새로 추가된 prop
      />

      {/* 일일 사용량 표시 제거 */}
      {/* <div className="bg-gray-700 text-white p-2 text-center">
        {language === "ko"
          ? `오늘의 사용량: ${dailyUsage.count}/30`
          : `Today's usage: ${dailyUsage.count}/30`}
      </div> */}

      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={() => setIsMenuOpen(false)}
        ></div>
      )}
      <SlideMenu
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        mode={mode}
        setMode={setMode}
        language={language}
        className="custom-scrollbar"
        onStyleSelect={setSelectedStyle}
      />

      <RightMenu
        isOpen={isRightMenuOpen}
        onClose={() => setIsRightMenuOpen(false)}
        language={language}
        setLanguage={setLanguage}
        handleCodeLOLsLabClick={handleCodeLOLsLabClick}
        handleEmailClick={handleEmailClick}
        signOut={() => auth.signOut()}
      />
      <main
        className="flex-grow overflow-auto p-4 custom-scrollbar"
        ref={chatContainerRef}
      >
        <ChatMessages
          messages={chatMessages.map((msg) => ({
            ...msg,
            commentedCode: highlightComments(msg.commentedCode),
          }))}
          language={language}
          copyToClipboard={CodeCapture.copyToClipboard}
          captureCode={CodeCapture.captureCode}
          scrollbarStyles={CodeCapture.scrollbarStyles}
        />
        {isLoading && <LoadingUI message={loadingMessage} />}
      </main>

      {showCodeLOLsLabInfo && (
        <CodeLOLsLabInfo
          language={language}
          onClose={handleCloseCodeLOLsLabInfo}
          handleEmailClick={handleEmailClick}
          handleSubscribe={handleSubscribe}
          userEmail={user?.email || null}
        />
      )}

      <TextSelection
        language={language}
        onTextSelected={setSelectedText}
        containerRef={chatContainerRef}
      />

      {selectedText && (
        <DragCapture
          selectedText={selectedText}
          language={language}
          onCapture={(capturedImage) => {
            // 캡처된 이미지 처리 로직
            console.log("Captured image:", capturedImage);
          }}
        />
      )}

      <footer className="bg-surface p-4">
        <div className="container mx-auto flex items-center">
          <div className="flex-grow">
            <CodeInput onSubmit={handleCodeSubmit} language={language} />
          </div>
          <div className="ml-4 text-sm text-gray-400">
            {language === "ko"
              ? `${dailyUsage.count}/30`
              : `${dailyUsage.count}/30`}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database } from "../firebase";
import { ref, onValue, off, update } from "firebase/database";
import { Style, Language } from "../types";
import StyleCard from "../components/StyleCard";
import { Link } from "react-router-dom";
import LanguageSelector from "../components/LanguageSelector";
import { useLanguage } from "../contexts/LanguageContext";

const MyStyles: React.FC = () => {
  const [user] = useAuthState(auth);
  const [myStyles, setMyStyles] = useState<Style[]>([]);
  const [addedStyles, setAddedStyles] = useState<Style[]>([]);
  const { language, setLanguage } = useLanguage();
  const [userReactions, setUserReactions] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState<'created' | 'added'>('created');

  useEffect(() => {
    if (user) {
      const stylesRef = ref(database, "styles");
      const userAddedStylesRef = ref(database, `users/${user.uid}/addedStyles`);
      const userHiddenStylesRef = ref(
        database,
        `users/${user.uid}/hiddenStyles`
      );

      const unsubscribe = onValue(stylesRef, (snapshot) => {
        const allStyles = snapshot.val();
        if (allStyles) {
          onValue(userAddedStylesRef, (userAddedSnapshot) => {
            const userAddedStyles = userAddedSnapshot.val() || {};
            onValue(userHiddenStylesRef, (userHiddenSnapshot) => {
              const userHiddenStyles = userHiddenSnapshot.val() || {};

              const myStylesArray = Object.entries(allStyles)
                .filter(
                  ([styleId, styleData]: [string, any]) =>
                    styleData.createdBy === user.uid &&
                    !userHiddenStyles[styleId]
                )
                .map(([styleId, styleData]: [string, any]) => ({
                  id: styleId,
                  ...styleData,
                }))
                .sort((a, b) => b.createdAt - a.createdAt);

              const addedStylesArray = Object.entries(userAddedStyles)
                .filter(
                  ([styleId]) =>
                    allStyles[styleId] && !userHiddenStyles[styleId]
                )
                .map(([styleId]) => ({
                  id: styleId,
                  ...allStyles[styleId],
                }))
                .sort((a, b) => b.createdAt - a.createdAt);

              setMyStyles(myStylesArray);
              setAddedStyles(addedStylesArray);
            });
          });
        } else {
          setMyStyles([]);
          setAddedStyles([]);
        }
      });

      // 사용자 반응 데이터 가져오기
      const userReactionsRef = ref(database, `userReactions/${user.uid}`);
      onValue(userReactionsRef, (snapshot) => {
        setUserReactions(snapshot.val() || {});
      });

      return () => {
        off(stylesRef);
        off(userAddedStylesRef);
        off(userHiddenStylesRef);
        off(userReactionsRef);
      };
    }
  }, [user]);

  const handleReaction = (
    style: Style,
    reactionType: "like" | "funny" | "interesting" | "risky"
  ) => {
    // 반응 처리 로직 구현
  };

  const removeAddedStyle = (styleId: string) => {
    if (user) {
      const userAddedStylesRef = ref(
        database,
        `users/${user.uid}/addedStyles/${styleId}`
      );
      update(userAddedStylesRef, { [styleId]: null })
        .then(() => {
          setAddedStyles((prevStyles) =>
            prevStyles.filter((style) => style.id !== styleId)
          );
        })
        .catch((error) => {
          console.error("Error removing style:", error);
          alert(
            language === "ko"
              ? "스타일 삭제 중 오류가 발생했습니다."
              : "An error occurred while removing the style."
          );
        });
    }
  };

  const removeMyStyle = (styleId: string) => {
    if (user) {
      const userHiddenStylesRef = ref(
        database,
        `users/${user.uid}/hiddenStyles/${styleId}`
      );
      update(userHiddenStylesRef, { [styleId]: true })
        .then(() => {
          setMyStyles((prevStyles) =>
            prevStyles.filter((style) => style.id !== styleId)
          );
        })
        .catch((error) => {
          console.error("Error hiding style:", error);
          alert(
            language === "ko"
              ? "스타일 숨기기 중 오류가 발생했습니다."
              : "An error occurred while hiding the style."
          );
        });
    }
  };

  const addComment = (styleId: string, comment: string) => {
    // 댓글 추가 로직 구현
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <header className="bg-gray-800 p-4 flex justify-between items-center">
        <Link to="/" className="text-2xl">
          👈
        </Link>
        <h1 className="text-2xl font-bold text-primary">
          {language === "ko" ? "스타일 저장소" : "Style Store"}
        </h1>
        <LanguageSelector language={language} setLanguage={setLanguage} />
      </header>

      <main className="container mx-auto p-4">
        <div className="flex justify-center mb-6">
          <button
            onClick={() => setSelectedTab('created')}
            className={`px-4 py-2 rounded-l-lg ${
              selectedTab === 'created' ? 'bg-primary text-white' : 'bg-gray-700 text-gray-300'
            }`}
          >
            {language === "ko" ? "내가 만든 스타일" : "My Created Styles"}
          </button>
          <button
            onClick={() => setSelectedTab('added')}
            className={`px-4 py-2 rounded-r-lg ${
              selectedTab === 'added' ? 'bg-primary text-white' : 'bg-gray-700 text-gray-300'
            }`}
          >
            {language === "ko" ? "추가한 스타일" : "Added Styles"}
          </button>
        </div>

        {selectedTab === 'created' && (
          <>
            <h2 className="text-xl font-bold mb-4">
              {language === "ko" ? "내가 만든 스타일" : "My Created Styles"}
            </h2>
            {myStyles.length === 0 ? (
              <div className="text-center mt-4 mb-8">
                {language === "ko"
                  ? "아직 만든 스타일이 없습니다."
                  : "You haven't created any styles yet."}
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                {myStyles.map((style) => (
                  <StyleCard
                    key={style.id}
                    style={style}
                    userReactions={userReactions}
                    handleReaction={handleReaction}
                    addToMyStyles={() => {}}
                    addComment={addComment}
                    comments={style.comments || []}
                    language={language}
                    isAdded={true}
                    removeStyle={() => removeMyStyle(style.id)}
                    showRemoveButton={true}
                    removeText={language === "ko" ? "숨기기" : "Hide"}
                    showRemoveButtonOnTop={true}
                    isCodeLoading={false}
                  />
                ))}
              </div>
            )}
          </>
        )}

        {selectedTab === 'added' && (
          <>
            <h2 className="text-xl font-bold mb-4">
              {language === "ko" ? "추가한 스타일" : "Added Styles"}
            </h2>
            {addedStyles.length === 0 ? (
              <div className="text-center mt-4">
                {language === "ko"
                  ? "아직 추가한 스타일이 없습니다."
                  : "You haven't added any styles yet."}
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {addedStyles.map((style) => (
                  <StyleCard
                    key={style.id}
                    style={style}
                    userReactions={userReactions}
                    handleReaction={handleReaction}
                    addToMyStyles={() => {}}
                    addComment={addComment}
                    comments={style.comments || []}
                    language={language}
                    isAdded={true}
                    removeStyle={() => removeAddedStyle(style.id)}
                    showRemoveButton={true}
                    removeText={language === "ko" ? "삭제" : "Remove"}
                    showRemoveButtonOnTop={true}
                    isCodeLoading={false}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default MyStyles;

import React from "react";
import { ChatMessage, Language } from "../types";
import CodeComparison from "./CodeComparison";

interface ChatMessagesProps {
  messages: ChatMessage[];
  language: Language;
  copyToClipboard: (text: string) => void;
  captureCode: (originalCode: string, commentedCode: string) => void;
  scrollbarStyles: string;
}

export const ChatMessages: React.FC<ChatMessagesProps> = ({
  messages,
  language,
  copyToClipboard,
  captureCode,
  scrollbarStyles,
}) => {
  return (
    <>
      <style>{scrollbarStyles}</style>
      {messages.map((message) => (
        <div key={message.id} className="mb-8">
          <div className="text-sm text-gray-500 mb-2">
            {message.timestamp.toLocaleString()}
          </div>
          <CodeComparison
            originalCode={message.originalCode}
            commentedCode={message.commentedCode}
            language={language}
            scrollbarStyles={scrollbarStyles}
          />
          {/* ... (나머지 JSX 유지) */}
        </div>
      ))}
    </>
  );
};
